import React from 'react';
import { Modal } from 'react-bootstrap';

type BlitzModalProps = {
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode;
};

const BlitzModal: React.FC<BlitzModalProps> = ({
    isOpen,
    onClose,
    children,
}) => {
    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton className="border-0" />
            <Modal.Body className="d-flex flex-column gap-2 pt-0">
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default BlitzModal;
