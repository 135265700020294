import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { globalURLS } from '../../Global/urls';
import {
    addIdToTalks,
    clearAdgenda,
    filterByDayRank,
    filterByHall,
    getAllBlitzSpeakers,
    getDateOnly,
    getFilteredHalls,
    upgradeBlitzAgenda,
} from './helpers/utils';
import { Container, Row, Badge, Spinner, Button } from 'react-bootstrap';
import {
    IBlitzDay,
    IBlitzHall,
    IBlitzInfoType,
    IBlitzSession,
    IPayLoad,
} from './typings';
import BlitzCard from './BlitzCard';
import AddDayModal from './Modal/AddDayModal';
import AddHallModal from './Modal/AddHallModal';
import AddAndEditSessionModal from './Modal/AddAndEditSessionModal';
import { da } from 'date-fns/locale';

const BlitzPage: React.FC = () => {
    const { blitzId } = useParams();
    const [blitzInfo, setBlitzInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [speakers, setSpeakers] = useState([]);
    const [activeDayRank, setActiveDayRank] = useState<number>(-1);
    const [hallsOfDay, setHallsOfDay] = useState<IBlitzHall[]>([]);
    const [activeHallId, setActiveHallId] = useState<string>('');

    const [activeDay, setActiveDay] = useState<IBlitzDay>(null);
    const [activeHall, setActiveHall] = useState<IBlitzHall>(null);
    const [selectedSession, setSelectedSession] = useState<IBlitzSession>(null);

    const [addDayModalOpen, setAddDayModalOpen] = useState(false);
    const [addHallModalOpen, setAddHallModalOpen] = useState(false);
    const [addSessionModalOpen, setAddSessionModalOpen] = useState(false);

    const deleteSession = (id: string) => {
        const cfrm: boolean = confirm(
            'Are you sure you want to delete this session?',
        );

        if (cfrm) {
            upgradeBlitzAgenda(
                {
                    blitz_short_id: blitzInfo.short_id,
                    days: blitzInfo.days,
                    halls: blitzInfo.halls,
                    sessions: blitzInfo.sessions.filter(
                        (s: IBlitzSession) => s.id !== id,
                    ),
                },
                (payload: IPayLoad) => {
                    setBlitzInfo({
                        ...blitzInfo,
                        sessions: payload.sessions,
                    });
                },
            );
        }
    };

    const getBlitzInfo = async (payload: { blitz_short_id: string }) => {
        try {
            setLoading(true);
            const { data } = await axios.post(
                `${globalURLS.baseURL}blitz_get_info`,
                payload,
                globalURLS.config,
            );
            if (!data.success) {
                setBlitzInfo(null);
                return;
            }
            addIdToTalks(data.data.sessions);
            setBlitzInfo(data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBlitzInfo({ blitz_short_id: blitzId });
    }, [blitzId]);

    useEffect(() => {
        if (blitzInfo) {
            const allSpeakers = getAllBlitzSpeakers(blitzInfo.sessions || []);
            setSpeakers(allSpeakers);

            if (!blitzInfo || blitzInfo.days?.length === 0) {
                setActiveDayRank(-1);
                setActiveDay(null);
                return;
            }

            const activeDayByRank = blitzInfo.days.find(
                (d: IBlitzDay) => d.rank === activeDayRank,
            );

            if (!activeDayByRank) {
                setActiveDayRank(blitzInfo.days[0].rank);
                setActiveDay(blitzInfo.days[0]);
            }
        }
    }, [blitzInfo]);

    useEffect(() => {
        if (blitzInfo && activeDayRank) {
            setHallsOfDay(
                getFilteredHalls(blitzInfo.halls, activeDayRank + ''),
            );
        }
    }, [blitzInfo?.halls, activeDayRank]);

    useEffect(() => {
        if (hallsOfDay && hallsOfDay.length > 0) {
            setActiveHallId(hallsOfDay[0].id);
            setActiveHall(hallsOfDay[0]);
        }
    }, [hallsOfDay]);

    const sessions: IBlitzSession[] = useMemo(() => {
        if (blitzInfo && activeDayRank && activeHallId) {
            return filterByHall(
                filterByDayRank(blitzInfo.sessions, activeDayRank + ''),
                activeHallId,
            );
        }
        return [];
    }, [blitzInfo, activeDayRank, activeHallId]);

    if (loading) {
        return (
            <Container className="text-center">
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    // clearAdgenda('test1');

    return (
        <>
            <Container
                className="py-3"
                style={{ height: 'fit-content', width: '800px' }}
            >
                <h1 className="mb-4">{blitzInfo?.name} Blitz Event</h1>

                {blitzInfo?.days.length > 0 ? (
                    <Row className="mb-4">
                        <h4>Days</h4>
                        {/* Render clickable Day pills */}
                        <div className="d-flex flex-wrap gap-2 align-items-center">
                            {blitzInfo?.days.map((day: IBlitzDay) => (
                                <Button
                                    key={day.rank}
                                    variant={
                                        activeDayRank === day.rank
                                            ? 'success'
                                            : 'secondary'
                                    }
                                    className="d-flex align-items-center gap-1 px-2"
                                    style={{
                                        lineHeight: '1rem',
                                        height: '2rm',
                                        padding: '7px 0.5rem',
                                    }}
                                    onClick={() => {
                                        setActiveDayRank(day.rank);
                                        setActiveDay(day);
                                    }}
                                >
                                    Day {day.rank}
                                </Button>
                            ))}
                            <Button
                                variant="outline-primary"
                                className="d-flex align-items-center gap-1 px-2"
                                style={{
                                    lineHeight: '1rem',
                                    height: '2rm',
                                    padding: '7px 0.5rem',
                                }}
                                onClick={() => setAddDayModalOpen(true)}
                            >
                                Add Day
                            </Button>
                        </div>
                    </Row>
                ) : (
                    <Button
                        variant="outline-primary"
                        className="d-flex align-items-center gap-1 px-2"
                        style={{
                            lineHeight: '1rem',
                            height: '2rm',
                            padding: '7px 0.5rem',
                        }}
                        onClick={() => setAddDayModalOpen(true)}
                    >
                        Add Day
                    </Button>
                )}

                {blitzInfo.days.length > 0 && (
                    <>
                        {activeDayRank && hallsOfDay.length > 0 ? (
                            <Row className="mb-4">
                                <h4>
                                    Halls for Day {activeDayRank} (
                                    {getDateOnly(
                                        blitzInfo.days.find(
                                            (day: IBlitzDay) =>
                                                day.rank === activeDayRank,
                                        ).date,
                                    )}
                                    )
                                </h4>
                                {/* Render clickable Hall pills for the selected day */}
                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                    {hallsOfDay.map((hall: IBlitzHall) => (
                                        <Button
                                            key={hall.id}
                                            variant={
                                                activeHallId === hall.id
                                                    ? 'success'
                                                    : 'secondary'
                                            }
                                            className="d-flex align-items-center gap-1 px-2"
                                            style={{
                                                lineHeight: '1rem',
                                                height: '2rm',
                                                padding: '7px 0.5rem',
                                            }}
                                            onClick={() => {
                                                setActiveHallId(hall.id);
                                                setActiveHall(hall);
                                            }}
                                        >
                                            {hall.name}
                                        </Button>
                                    ))}
                                    <Button
                                        variant="outline-primary"
                                        className="d-flex align-items-center gap-1 px-2"
                                        style={{
                                            lineHeight: '1rem',
                                            height: '2rm',
                                            padding: '7px 0.5rem',
                                        }}
                                        onClick={() =>
                                            setAddHallModalOpen(true)
                                        }
                                    >
                                        Add Hall
                                    </Button>
                                </div>
                            </Row>
                        ) : (
                            <Button
                                variant="outline-primary"
                                className="d-flex align-items-center gap-1 px-2"
                                style={{
                                    lineHeight: '1rem',
                                    height: '2rm',
                                    padding: '7px 0.5rem',
                                }}
                                onClick={() => setAddHallModalOpen(true)}
                            >
                                Add Hall
                            </Button>
                        )}
                    </>
                )}

                {blitzInfo?.days?.length > 0 && hallsOfDay?.length > 0 && (
                    <>
                        {sessions.length > 0 ? (
                            <div className="d-flex align-items-flex-start justify-content-between gap-2 mb-3">
                                <h4>Sessions ({sessions.length}):</h4>
                                <Button
                                    variant="outline-primary"
                                    className="d-flex align-items-center gap-1 px-2"
                                    style={{
                                        lineHeight: '1rem',
                                        height: '2rm',
                                        padding: '7px 0.5rem',
                                    }}
                                    onClick={() => setAddSessionModalOpen(true)}
                                >
                                    Add Sessions
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="outline-primary"
                                className="d-flex align-items-center gap-1 px-2"
                                style={{
                                    lineHeight: '1rem',
                                    height: '2rm',
                                    padding: '7px 0.5rem',
                                }}
                                onClick={() => setAddSessionModalOpen(true)}
                            >
                                Add Sessions
                            </Button>
                        )}
                    </>
                )}
                <div>
                    {sessions.map((session: IBlitzSession) => (
                        <BlitzCard
                            key={session.id}
                            session={session}
                            setSelectedSession={setSelectedSession}
                            setAddSessionModalOpen={setAddSessionModalOpen}
                            deleteSession={() => deleteSession(session.id)}
                            blitzInfo={blitzInfo}
                            setBlitzInfo={setBlitzInfo}
                            speakers={speakers}
                        />
                    ))}
                </div>
            </Container>

            {addDayModalOpen && (
                <AddDayModal
                    isOpen={addDayModalOpen}
                    onClose={() => setAddDayModalOpen(false)}
                    onSuccess={(payload: IPayLoad) => {
                        setBlitzInfo({ ...blitzInfo, days: payload.days });
                    }}
                    blitzInfo={blitzInfo}
                />
            )}

            {activeDayRank && addHallModalOpen && (
                <AddHallModal
                    isOpen={addHallModalOpen}
                    onClose={() => setAddHallModalOpen(false)}
                    onSuccess={(payload: IPayLoad) => {
                        setBlitzInfo({ ...blitzInfo, halls: payload.halls });
                    }}
                    blitzInfo={blitzInfo}
                    activeDayRank={activeDayRank}
                />
            )}

            {activeDay && activeHall && addSessionModalOpen && (
                <AddAndEditSessionModal
                    isOpen={addSessionModalOpen}
                    onClose={() => {
                        setSelectedSession(null);
                        setAddSessionModalOpen(false);
                    }}
                    onSuccess={(payload: IPayLoad) => {
                        setBlitzInfo({
                            ...blitzInfo,
                            sessions: payload.sessions,
                        });
                    }}
                    blitzInfo={blitzInfo}
                    selectedSession={selectedSession}
                    activeDay={activeDay}
                    activeHall={activeHall}
                />
            )}
        </>
    );
};

export default BlitzPage;
