import React from 'react';
import { Modal, Button } from 'react-bootstrap';

type SuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data: {
    error?: string;
    link?: string;
    passcode?: string;
    maxAttempts?: number;
    expiry?: string;
  };
};

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, data }) => {
  const [copied, setCopied] = React.useState(false);
  const date = new Date(data?.expiry);
  const textToCopy = `Link: ${data.link}\nPasscode: ${data.passcode}\nMax Attempts: ${data.maxAttempts}\nExpiry: ${date.toLocaleString()}`;
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 10000);
  }
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton className='border-0' />
      <Modal.Body className='d-flex flex-column gap-2 pt-0'>
        {data.error ? (
          <>
            <div className='text-center fw-bold fs-4 mb-2'>Failed to create Magic Link</div>
            <div className='text-center mb-4'>
                {data.error}
            </div>
          </>
        ) : (
        <>
            <div className='text-center fw-bold fs-4 mb-2'>Magic Link Created Successfully</div>
            <div>
                <strong>Link:</strong>{' '}
                <a href={data.link} target="_blank" rel="noopener noreferrer">
                    {data.link}
                </a>
            </div>
            <div><strong>Passcode:</strong> {data.passcode}</div>
            <div><strong>Max Attempts:</strong> {data.maxAttempts}</div>
            <div className='mb-2'><strong>Expiry:</strong> {date.toLocaleString()}</div>
            <Button variant='primary' className='align-self-center mb-2' onClick={handleCopy} disabled={copied}>
                {copied ? 'Copied!' : 'Click to Copy'}
            </Button>
        </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
