import React, { useEffect, useState } from 'react';
import BlitzModal from './BlitzModal';
import { getDefaultSession, upgradeBlitzAgenda } from '../helpers/utils';
import {
    IBlitzDay,
    IBlitzHall,
    IBlitzInfoType,
    IBlitzSession,
    IBlitzSpeakerInfo,
    IPayLoad,
} from '../typings';
import { v4 as uuidv4 } from 'uuid';

import SessionDetailsForm from './SessionDetailsForm';
import { Button, Form } from 'react-bootstrap';

type AddAndEditSessionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (payload: IPayLoad) => void;
    blitzInfo: IBlitzInfoType;
    selectedSession?: IBlitzSession;
    activeDay: IBlitzDay;
    activeHall: IBlitzHall;
};

const AddAndEditSessionModal: React.FC<AddAndEditSessionModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    blitzInfo,
    selectedSession,
    activeDay,
    activeHall,
}) => {
    const [session, setSession] = useState<IBlitzSession>(() =>
        getDefaultSession(activeDay, activeHall),
    );

    const [formTab, setFormTab] = useState<'session' | 'talks' | 'quiz'>(
        'session',
    );

    useEffect(() => {
        if (selectedSession) {
            setSession(selectedSession);
        }
    }, [selectedSession]);

    const handleSubmit = () => {
        const newSession = session;

        if (!selectedSession) {
            const sessionId = uuidv4();
            newSession.id = sessionId;
        }

        upgradeBlitzAgenda(
            {
                blitz_short_id: blitzInfo.short_id,
                days: blitzInfo.days,
                halls: blitzInfo.halls,
                sessions: selectedSession
                    ? blitzInfo.sessions.map(s => {
                          if (s.id === newSession.id) {
                              return newSession;
                          } else {
                              return s;
                          }
                      })
                    : [...blitzInfo.sessions, newSession],
            },
            onSuccess,
        );
        onClose();
    };

    return (
        <BlitzModal isOpen={isOpen} onClose={onClose}>
            <div className="text-center fw-bold fs-4 mb-3">
                {selectedSession ? 'Edit Session' : 'Create New Session'}
            </div>

            <Form className="my-3 mx-3" onSubmit={handleSubmit}>
                <SessionDetailsForm session={session} setSession={setSession} />
                {/* Save or create button */}
                <Button variant="primary" type="submit" className="w-100">
                    {selectedSession ? 'Save Changes' : 'Create Session'}
                </Button>
            </Form>
        </BlitzModal>
    );
};

export default AddAndEditSessionModal;
