import React, { useEffect } from 'react';
import { IBlitzSession } from '../typings';
import { formatEpochToTime } from '../helpers/utils';

type SessionDetailsFormProps = {
    session: IBlitzSession;
    setSession: (session: IBlitzSession) => void;
};

const SessionDetailsForm: React.FC<SessionDetailsFormProps> = ({
    session,
    setSession,
}) => {
    const onTimeChange = (e: any) => {
        const { value, name } = e.target;
        const sessionDayEpoch = session.day_date;
        const [h, m] = value.split(':').map(Number);
        const ts = new Date(sessionDayEpoch).setHours(h, m, 0, 0);

        setSession({
            ...session,
            [name]: ts,
        });
    };
    return (
        <div>
            <div className="mb-3 d-flex flex-column gap-1">
                <label htmlFor="sessionName" className="mb-0">
                    Session Name:
                </label>
                <input
                    type="text"
                    id="sessionName"
                    name="name"
                    placeholder="Enter session name"
                    value={session.name}
                    onChange={e => {
                        setSession({
                            ...session,
                            name: e.target.value,
                        });
                    }}
                    required
                    className="p-2"
                />
            </div>

            <div className="mb-3 d-flex flex-column gap-1">
                <label htmlFor="sessionRank" className="mb-0">
                    Rank:
                </label>
                <input
                    type="number"
                    id="sessionRank"
                    name="rank"
                    placeholder="Enter rank (optional)"
                    value={session.rank}
                    onChange={e => {
                        const value = e.target.value;
                        setSession({
                            ...session,
                            rank: value ? Number(value) : undefined,
                        });
                    }}
                    className="p-2"
                    min={'1'}
                />
            </div>

            <div className="mb-3 d-flex flex-column gap-1">
                <label htmlFor="startTime" className="mb-0">
                    Start Time:
                </label>
                <input
                    type="time"
                    id="startTime"
                    name="starttime"
                    value={formatEpochToTime(session.starttime)}
                    onChange={onTimeChange}
                    required
                    className="p-2"
                />
            </div>

            <div className="mb-3 d-flex flex-column gap-1">
                <label htmlFor="endTime" className="mb-0">
                    End Time:
                </label>
                <input
                    type="time"
                    id="endTime"
                    name="endtime"
                    value={formatEpochToTime(session.endtime)}
                    onChange={onTimeChange}
                    required
                    className="p-2"
                />
            </div>

            <div className="fw-semibold mb-3">
                You can manage talks & speakers from session card after the
                session will be created.
            </div>
        </div>
    );
};

export default SessionDetailsForm;
