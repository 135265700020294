import React, { useState } from 'react';
import BlitzModal from './BlitzModal';
import { formatEpochToTime, upgradeBlitzAgenda } from '../helpers/utils';
import {
    IBlitzInfoType,
    IBlitzSession,
    IBlitzTalkInfo,
    IPayLoad,
} from '../typings';
import { Button, Form } from 'react-bootstrap';

type AddTalkModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (payload: IPayLoad) => void;
    talk?: IBlitzTalkInfo | null;
    session: IBlitzSession;
    blitzInfo: IBlitzInfoType;
};

const AddTalkModal: React.FC<AddTalkModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    talk,
    session,
    blitzInfo,
}) => {
    const [talkState, setTalkState] = useState<IBlitzTalkInfo>(
        talk || { name: '', starttime: 0, endtime: 0, speakers: [] },
    );

    const onTimeChange = (e: any) => {
        const { value, name } = e.target;
        const sessionDayEpoch = session.day_date;
        const [h, m] = value.split(':').map(Number);
        const ts = new Date(sessionDayEpoch).setHours(h, m, 0, 0);

        setTalkState({
            ...talkState,
            [name]: ts,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!talk) {
            talkState.id = session.talks.length + 1 + '';
        }
        upgradeBlitzAgenda(
            {
                blitz_short_id: blitzInfo.short_id,
                days: blitzInfo.days,
                halls: blitzInfo.halls,
                sessions: blitzInfo.sessions.map((s: IBlitzSession) => {
                    if (s.id === session.id) {
                        return {
                            ...s,
                            talks: talk
                                ? s.talks.map((t: IBlitzTalkInfo) =>
                                      t.id === talk.id ? talkState : t,
                                  )
                                : [...s.talks, talkState],
                        };
                    } else {
                        return s;
                    }
                }),
            },
            onSuccess,
        );
        onClose();
    };

    return (
        <BlitzModal isOpen={isOpen} onClose={onClose}>
            <div className="text-center fw-bold fs-4 mb-3">Add Talk</div>
            {/* date input */}
            <Form className="mb-3 mx-3" onSubmit={handleSubmit}>
                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="name" className="mb-0">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={talkState.name}
                        placeholder="Enter talk name"
                        onChange={e =>
                            setTalkState({ ...talkState, name: e.target.value })
                        }
                        required
                        className="p-2"
                    />
                </div>
                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="startTime" className="mb-0">
                        Start Time:
                    </label>
                    <input
                        type="time"
                        id="startTime"
                        name="starttime"
                        value={formatEpochToTime(talkState.starttime)}
                        onChange={onTimeChange}
                        required
                        className="p-2"
                    />
                </div>
                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="endTime" className="mb-0">
                        End Time:
                    </label>
                    <input
                        type="time"
                        id="endTime"
                        name="endtime"
                        value={formatEpochToTime(talkState.endtime)}
                        onChange={onTimeChange}
                        required
                        className="p-2"
                    />
                </div>

                <Button variant="primary" type="submit" className="w-100">
                    {talk ? 'Save Changes' : 'Create Talk'}
                </Button>
            </Form>
        </BlitzModal>
    );
};

export default AddTalkModal;
