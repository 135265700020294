import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

export const updaterequest_getall = async () => {
    const response = await axios.post(`${globalURLS.baseURL}userV3_updaterequest_getall`, {}, globalURLS.config);
    return response.data?.data ?? [];
};

export const updaterequest_process = async (data) => {
    const response = await axios.post(`${globalURLS.baseURL}userV3_updaterequest_process`, data, globalURLS.config);
    return response.data?.success;
};
