import axios from "axios";
import { globalURLS } from "../../../Global/urls";
import { IBlitzDay, IBlitzHall, IBlitzSession, IBlitzSpeakerInfo, IBlitzTalkInfo, IPayLoad } from "../typings";

export const filterByDayRank = (
  sessions: IBlitzSession[],
  rank: string | undefined
): IBlitzSession[] => {
  if (!rank) return sessions;
  return sessions.filter((session) => session.day_rank === Number(rank));
};

export const filterByHall = (
  sessions: IBlitzSession[],
  hallId: string | undefined
): IBlitzSession[] => {
  if (!hallId) return sessions;
  return sessions.filter((session) => session.hall_id === hallId);
};

export const getFilteredHalls = (
    halls: IBlitzHall[],
    dayRank: string | undefined
  ): IBlitzHall[] => {
    if (!dayRank) {
      return halls.filter((hall) => hall.name);
    }
  
    return halls.filter((hall) => hall.day_rank == Number(dayRank) && hall.name);
  };

export const getAllBlitzSpeakers = (sessions: IBlitzSession[]): IBlitzSpeakerInfo[] => {
    if (sessions?.length) {
        const allSpeakers = sessions?.reduce?.(
            (acc: IBlitzSpeakerInfo[], session) => {
                // Merge speakers from each session and ensure uniqueness by `id`
                session?.talks?.forEach?.((talk) => {
                    talk?.speakers?.forEach?.((speaker) => {
                        if (
                            !acc?.some((existingSpeaker) => existingSpeaker?.id === speaker?.id)
                        ) {
                            acc?.push(speaker);
                        }
                    });
                });
                return acc;
            },
            []
        );
        return allSpeakers || [];
    }

    return [];
}

export const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const getDateOnly = (timestamp: number) => {
    const date = new Date(timestamp);
    // dd/mm/yyyy
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const formatEpochToTime = (epoch: number) => {
  if(!epoch) return '';
  const date = new Date(epoch);
  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();

  // Ensure the time is in the format HH:mm (2 digits each)
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
};

export const upgradeBlitzAgenda = async (payload: IPayLoad, onSuccess?: (payload: IPayLoad) => void, onFailure?: () => void) => {
    const payloadClone = structuredClone(payload)
    removeIdFromTalks(payloadClone.sessions);
    try {
      const { data } = await axios.post(
        `${globalURLS.baseURL}blitz_updateagenda`,
        payloadClone,
        globalURLS.config,
      )

      if (data?.success) {
        onSuccess?.(payload);
      } else {
        onFailure?.();
      }
    }
    catch (error) {
      console.log(error);
      onFailure?.();
    }
};

export const clearAdgenda = async (blitzId: string) => {
  upgradeBlitzAgenda({
    blitz_short_id: blitzId,
    days: [],
    halls: [],
    sessions: [],
  });
}

export const getDefaultSession = (activeDay: IBlitzDay, activeHall: IBlitzHall) => {
  return {
    id: '',
    name: '',
    starttime: 0,
    endtime: 0,
    talks: [] as IBlitzTalkInfo[],
  
    day_id: activeDay.id,
    day_date: activeDay.date,
    day_rank: activeDay.rank,
    hall_id: activeHall.id,
    hall_name: activeHall.name,
  
    has_quiz: false,
    quiz_starttime: 0,
    quiz_endtime: 0,
    quiz_status: 'ended',
    quiz_short_id: '',
  } as IBlitzSession
}

export const addIdToTalks = (sessions: IBlitzSession[]) => {
  sessions.forEach(s => {
    s.talks.forEach((t, index) => {
      t.id = (index + 1) + '';
    })
  })
}

export const removeIdFromTalks = (sessions: IBlitzSession[]) => {
  sessions.forEach(s => {
    s.talks.forEach(t => {
      delete t.id;
    })
  })
}