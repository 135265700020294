/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.esm.js';
import axios from 'axios';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import {
    Button,
    Modal,
    Form,
    InputGroup,
    Container,
    Image,
    Col,
    Row,
} from 'react-bootstrap';

import { BiEdit } from 'react-icons/bi/index.esm.js';
// Components imports
import './UpdateDiscussion.css';
import { globalURLS } from '../../../Global/urls.js';
import { useGlobalContext } from '../../../Context/index.tsx';
import BackGroundInputSelector from '../../Common/Inputs/BackGroundInputSelector.tsx';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';
import GenImageSelector from '../../Common/Inputs/GenImageSelector.tsx';
import ProductsBySelector from '../../Common/Inputs/ProductsBySelector.tsx';

const initalBrandConfig = {
    logoUrl: null,
    posterUrl: null,
    logoUrlModal: null,
    detailText: null,
    sizeMultiple: 1,
};

function UpdateDiscussion({ show, discussionid, onClose }) {
    const { showErrorToast, setShowSuccessToast, setShowErrorToast } =
        useGlobalContext();

    const [isLoading, setIsLoading] = useState(false);
    const [editDisable, setEditDisable] = useState(true);
    const [title, setTitle] = useState();
    const [clubs, setClubs] = useState([]);
    const [brandlogo, setBrandlogo] = useState('');
    const [club, setClub] = useState({});
    const [image, setImage] = useState();
    const [synopsis, setSynopsis] = useState();
    const [tageFor, setTagFor] = useState();
    const [conferenceid, setConferenceId] = useState('');
    const [summary, setSummary] = useState();
    const [jpegImage, setJpegImage] = useState();
    const [webImage, setWebImage] = useState();
    const [test, setTest] = useState(0);
    const [isPrivate, setIsPrivate] = useState(0);
    const [hideFromUpcoming, sethideFromUpcoming] = useState(0);
    const [date, setDate] = useState();
    const [status, setStatus] = useState();
    const [time, setTime] = useState();
    const [recordingURL, setRecordingURL] = useState();
    const [recordingDuration, setRecordingDuration] = useState();
    const [specialties, setSpecialties] = useState([]);
    const [isPfizerQuiz, setIsPfizerQuiz] = useState(0);
    const [arraySpec, setArraySpec] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [arraySpeak, setArraySpeak] = useState([]);
    const [internalHosts, setInternalHosts] = useState([]);
    const [arrayInternalHosts, setArrayInternalHosts] = useState([]);
    const [arrayModerators, setArrayModerators] = useState([]);
    const [arrayADSpotsAdmins, setArrayADSpotsAdmins] = useState([]);
    const [cme_points, setcme_points] = useState(0);
    const [selectedImagesSupportedBy, setselectedImagesSupportedBy] = useState([]);
    const [selectedImagesAssociations, setselectedImagesAssociations] = useState([]);
    const [productsBy, setproductsBy] = useState({});
    const [disclaimer_title, setdisclaimer_title] = useState('');
    const [disclaimer_body, setdisclaimer_body] = useState('');
    const [iscomingsoon, setiscomingsoon] = useState(0);
    const [partner_handle, setpartner_handle] = useState('');
    const [otp_enabled, setotp_enabled] = useState(true);

    // console.log('ModAdSpotAdmins', {
    //     arrayModerators: arrayModerators,
    //     arrayADSpotsAdmins: arrayADSpotsAdmins,
    // });
    const [selectedBackgroundImages, setSelectedBackgroundImages] = useState(
        [],
    );
    const [initBackgroundImages, setInitBackgroundImages] = useState(
        [],
    );
    const [tags, setTags] = useState([]);
    const [arrayTags, setArrayTags] = useState([]);
    const [extraData, setExtraData] = useState({
        oneLiner: '',
        certifier: '',
        replayWontBeAvaliable: false,
        isCertified: false,
        isQuizDiscussion: false,
        isPartnerDiscussion: false,
        stopAutomatedComms: false,
        hideKeyDetails: false,
        expectedDurationinms: 60,
        subtitle_hide: false
    });

    const [discussionStats, setDiscussionStats] = useState({
        viewcount: 0,
        ratingcount: 0,
        ratingavg: 0,
    });

    const [platform, setPlatform] = useState({ platform: '', videoid: '' });

    const [validated, setValidated] = useState(false);
    const [brandConfig, setBrandConfig] = useState(initalBrandConfig);
    const [regform1_fields_name, setregform1_fields_name] = useState({show: true, mandatory: true});
    const [regform1_fields_city, setregform1_fields_city] = useState({show: false, mandatory: true});
    const [regform1_fields_specialty, setregform1_fields_specialty] = useState({show: false, mandatory: true});
    const [regform1_fields_phone, setregform1_fields_phone] = useState({show: false, mandatory: true});
    const [regform1_fields_institute, setregform1_fields_institute] = useState({show: false, mandatory: true});
    const [regform1_fields_state, setregform1_fields_state] = useState({show: false, mandatory: true});
    const [regform1_fields_disclaimer, setregform1_fields_disclaimer] = useState('');
    const [regform1_fields_checkboxes, setregform1_fields_checkboxes] = useState([]);

    // const regform1_obj = {
    //     name: regform1_fields_name, city: regform1_fields_city,
    //     specialty: regform1_fields_specialty, phone: regform1_fields_phone,
    //     institute: regform1_fields_institute, state: regform1_fields_state,
    //     disclaimer: regform1_fields_disclaimer, checkboxes: regform1_fields_checkboxes
    // }

    const predefinedBGImageOptions = [
        {
            url: 'https://plexusmd.gumlet.io/ChangingPractice2_1692972466354.png',
            name: 'Changing Practice',
        },
        {
            url: 'https://plexusmd.gumlet.io/WizardsVirtualBG_1695387830279.png',
            name: 'Pfizer',
        },
    ];

    const { selectSpeakers, selectSpecialties } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectSpeakers: true,
            getSelectSpecialties: true,
            isDisabled: true,
        });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const discussionData = await axios.post(
                    `${globalURLS.baseURL}ADDashgetDiscussion`,
                    { discussionid: discussionid },
                    globalURLS.config,
                );

                const clubsData = await axios.get(globalURLS.clubsJSONURL);
                const specialtiesData = await axios.get(
                    globalURLS.specialtiesJSONURL,
                );

                const tagsData = await axios.post(
                    `${globalURLS.baseURL}ADgetTags`,
                    globalURLS.config,
                );

                if (discussionData?.data?.data) {
                    // console.log(
                    //     'discussionData?.data?.data',
                    //     discussionData?.data?.data,
                    // );

                    const {
                        conferenceid,
                        summary,
                        jpegImage,
                        webImage,
                        title,
                        clubid,
                        clubhost,
                        coverimage,
                        description,
                        isTest,
                        status,
                        tags,
                        isPrivate,
                        hideFromUpcoming,
                        brandlogo,
                        brandconfig,
                        isPfizerQuizDiscussion,
                        hosts,
                        internalhosts,
                        moderators,
                        adSpotAdmins,
                        startdate,
                        recordingDuration,
                        selectedBackgroundImages,
                        recordingURL,
                        extraData,
                        platform,
                        videoid,
                        viewcount,
                        ratingcount,
                        ratingavg,
                        cme_points,
                        supportedby, associations, productsby,
                        disclaimer_title, disclaimer_body,
                        iscomingsoon,
                        partner_handle, otp_enabled, regform1_fields,
                    } = discussionData.data.data;

                    setExtraData({
                        oneLiner: extraData?.onLiner || '',
                        certifier: extraData?.certifier || '',
                        replayWontBeAvaliable:
                            extraData?.replayWontBeAvaliable || false,
                        isCertified: extraData?.isCertified || false,
                        isQuizDiscussion: extraData?.isQuizDiscussion || false,
                        isPartnerDiscussion:
                            extraData?.isPartnerDiscussion || false,
                        stopAutomatedComms: extraData?.stopAutomatedComms || false,
                        expectedDurationinms: (extraData?.expectedDurationinms ?? 3600000) / 60000
                    });

                    setDiscussionStats({
                        viewcount: viewcount || 0,
                        ratingcount: ratingcount || 0,
                        ratingavg: ratingavg || 0,
                    });

                    setPlatform({ platform: platform, videoid: videoid });

                    let tFor;

                    if (clubid) tFor = 'club';
                    if (conferenceid) tFor = 'conference';
                    setTagFor(tFor || 'none');
                    setSummary(summary || '');
                    setJpegImage(jpegImage || '');
                    setWebImage(webImage || '');
                    setConferenceId(conferenceid || '');
                    setSelectedBackgroundImages(selectedBackgroundImages || []);
                    setInitBackgroundImages(selectedBackgroundImages || []);
                    setArrayTags(tags || []);
                    setTitle(title || '');
                    setClub(
                        clubhost?.clubname
                            ? {
                                  value: clubhost.clubid,
                                  label: clubhost.clubname,
                              }
                            : '',
                    );
                    setStatus(status);
                    setIsPfizerQuiz(isPfizerQuizDiscussion === true ? 1 : 0);
                    setBrandlogo(brandlogo || '');
                    // console.log('brandconfig', brandconfig);
                    setBrandConfig(brandconfig);
                    setImage(coverimage || '');

                    setSynopsis(description || '');
                    setTest(isTest || 0);
                    setIsPrivate(isPrivate || 0);
                    sethideFromUpcoming(!!hideFromUpcoming);
                    setRecordingURL(recordingURL || '');
                    setiscomingsoon(iscomingsoon || 0);

                    const finalDate = format(
                        new Date(startdate),
                        'yyyy-MM-dd',
                        { timeZone: 'Asia/Calcutta' },
                    );

                    setDate(finalDate);
                    setcme_points(cme_points ?? 0);
                    setselectedImagesSupportedBy((supportedby ?? []).map(i => i.imageurl));
                    setselectedImagesAssociations((associations ?? []).map(i => i.imageurl));
                    if (typeof productsby === 'object' && !Array.isArray(productsby)) setproductsBy(productsby);
                    setdisclaimer_title(disclaimer_title ?? '');
                    setdisclaimer_body(disclaimer_body ?? '');
                    
                    if (partner_handle?.length) setpartner_handle(partner_handle);
                    setotp_enabled(!!otp_enabled);
                    if (typeof regform1_fields === 'object') {
                        if (regform1_fields?.name) setregform1_fields_name(regform1_fields?.name);
                        if (regform1_fields?.city) setregform1_fields_city(regform1_fields?.city);
                        if (regform1_fields?.specialty) setregform1_fields_specialty(regform1_fields?.specialty);
                        if (regform1_fields?.phone) setregform1_fields_phone(regform1_fields?.phone);
                        if (regform1_fields?.institute) setregform1_fields_institute(regform1_fields?.institute);
                        if (regform1_fields?.state) setregform1_fields_state(regform1_fields?.state);
                        if (regform1_fields?.disclaimer) setregform1_fields_disclaimer(regform1_fields?.disclaimer);
                        if (regform1_fields?.checkboxes) setregform1_fields_checkboxes(regform1_fields?.checkboxes);
                    }

                    // Convert startdate to IST time string in 24-hour format
                    const finalTime = new Date(startdate).toLocaleString(
                        'en-US',
                        {
                            timeZone: 'Asia/Kolkata',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                        },
                    );
                    setTime(finalTime);

                    setRecordingDuration(recordingDuration);

                    const finalHosts =
                        hosts && hosts.length
                            ? hosts.map(el => {
                                  const host = {};
                                  host.value = el.accountid;
                                  host.label = `${el.firstname} ${el.lastname}`;
                                  return host;
                              })
                            : '';

                    const finalInternalHosts =
                        internalhosts && internalhosts.length
                            ? internalhosts.map(el => {
                                  const host = {};
                                  host.value = el.accountid;
                                  host.label = `${el.firstname} ${el.lastname}`;
                                  return host;
                              })
                            : '';

                    const finalAdSpotAdmins =
                        adSpotAdmins && adSpotAdmins.length
                            ? adSpotAdmins.map(el => {
                                  const host = {};
                                  host.value = el.accountid;
                                  host.label = `${el.firstname} ${el.lastname}`;
                                  return host;
                              })
                            : '';

                    const finalModerators =
                        Array.isArray(moderators) &&
                        moderators &&
                        moderators.length
                            ? moderators.map(el => {
                                  const host = {};
                                  host.value = el.accountid;
                                  host.label = `${el.firstname} ${el.lastname}`;
                                  return host;
                              })
                            : [];

                    //   setArraySpec('')
                    setArraySpeak(finalHosts || '');
                    setArrayInternalHosts(finalInternalHosts || '');
                    setArrayModerators(finalModerators);
                    setArrayADSpotsAdmins(finalAdSpotAdmins);
                }
                setTags(tagsData?.data?.data);
                setClubs(clubsData.data);
                setSpecialties(specialtiesData.data || []);
            } catch (e) {
                console.log('Error', e);
            }
        };
        if (discussionid) fetchData();
    }, [JSON.stringify(discussionid)]);

    const animatedComponents = makeAnimated();
    let utcDate = null;
    if (date && date.length && time && time.length) {
        utcDate = zonedTimeToUtc(`${date + ' ' + time}`, 'Asia/Calcutta');
    }

    const handleBackgroundImageChange = selectedImages => {
        setSelectedBackgroundImages(selectedImages);
    };

    const editEnable = () => setEditDisable(editDisable ? false : true);

    const handleClub = val => {
        setClub(val);
    };

    const handleTitle = e => {
        setTitle(e.target.value);
    };

    const handleImage = e => {
        setImage(e.target.value).trim();
    };

    const handleBrandLogo = e => {
        setBrandlogo(e.target.value).trim();
    };

    const handleRecordingURL = e => {
        setRecordingURL(e.target.value).trim();
    };

    const handleRecordingDuration = e => {
        setRecordingDuration(e.target.value);
    };

    const handleSynopsis = e => {
        setSynopsis(e.target.value);
    };

    const handleTest = () => {
        setTest(test ? 0 : 1);
    };

    const handlePrivate = () => {
        setIsPrivate(isPrivate ? 0 : 1);
    };

    const handlehideFromUpcoming = () => {
        sethideFromUpcoming(!hideFromUpcoming);
    };

    const handleDate = e => {
        setDate(e.target.value);
    };

    const handleTagFor = event => {
        const { value } = event.target;

        setClub({});
        setConferenceId(null);
        setTagFor(value || null);
    };

    const handleTime = e => {
        setTime(e.target.value);
    };

    const handleSpeakers = val => {
        setArraySpeak(val);
    };

    const handleInternalHosts = val => {
        setArrayInternalHosts(val);
    };

    const handleModerators = val => {
        setArrayModerators(val);
    };

    const handleADSportAdmins = val => {
        setArrayADSpotsAdmins(val);
    };

    const handlePfizerQuiz = () => {
        setIsPfizerQuiz(isPfizerQuiz ? 0 : 1);
        setExtraData({
            ...extraData,
            isQuizDiscussion: extraData?.isQuizDiscussion ? false : true,
        });
    };

    const handleBrandChange = e => {
        const { value, name } = e.target;

        if (name === 'brandLogoSmall') {
            setBrandlogo(value);
            setBrandConfig({ ...brandConfig, logoUrl: value.trim() });
        }

        if (name === 'posterURL') {
            setBrandlogo(value);
            setBrandConfig({ ...brandConfig, posterUrl: value.trim(), detailText: value.trim().length ? `![Promo](${value}) ${brandConfig.detailText || ''}` : brandConfig.detailText });
        }

        if (name === 'brandLogoFull') {
            setBrandConfig({ ...brandConfig, logoUrlModal: value.trim() });
        }

        if (name === 'detailText') {
            setBrandConfig({
                ...brandConfig,
                detailText: value,
            });
        }

        if (name === 'scale') {
            setBrandConfig({ ...brandConfig, sizeMultiple: Number(value) });
        }
    };

    const handleTags = val => {
        setArrayTags(val);
    };

    const handleExtraData = e => {
        const { value, name } = e.target;
        if (
            name === 'replayWontBeAvaliable' ||
            name === 'isCertified' ||
            name === 'isQuizDiscussion' ||
            name === 'isPartnerDiscussion' ||
            name === 'stopAutomatedComms' ||
            name === 'hideKeyDetails' || name === 'subtitle_hide'
        ) {
            setExtraData({
                ...extraData,
                [name]:
                    typeof extraData[name] === 'boolean'
                        ? !extraData[name]
                        : true,
            });
        } else {
            setExtraData({ ...extraData, [name]: value });
        }
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);

        // console.log('data', data);
        const respData = await axios.post(
            `${globalURLS.baseURL}updateDiscussions`,
            { discussions: [data] },
            globalURLS.config,
        );

        const platformBody = {
            discussionid: discussionid,
            platform: platform?.platform,
        };

        platform?.platform === 'youtube' && platform?.videoid
            ? (platformBody.metadata = { videoid: platform?.videoid })
            : (platformBody.metadata = {});

        await axios.post(
            `${globalURLS.baseURL}ADupdateDisPlatform`,
            platformBody,
            globalURLS.config,
        );

        setIsLoading(false);
        if (respData?.data?.success === true) {
            // window.location.reload();
            setShowSuccessToast(true);
            setEditDisable(true);
            onClose();
        } else {
            setShowErrorToast(true);
        }
    };

    const clubsData = clubs.map(el => ({
        value: el.clubid,
        label: el.name,
    }));

    const speakersData = speakers.map(el => ({
        value: el.accountid,
        label: `${el.name} - ${el.phone}`,
    }));

    const internalHostsData = internalHosts.map(el => ({
        value: el.accountid,
        label: `${el.name} - ${el.phone}`,
    }));

    let data = {};

    if (discussionid) data.discussionid = discussionid;
    if (title && title.length) data.name = title;
    if (club?.value) data.clubid = [club.value];
    if (utcDate) data.startdate = utcDate;
    if (isPfizerQuiz) data.isPfizerQuiz = isPfizerQuiz;
    if (image && image.length) data.image = [image];
    if (conferenceid) data.conferenceid = conferenceid;
    if (summary) data.summary = summary;
    if (jpegImage) data.jpegImage = jpegImage;
    if (webImage) data.webImage = webImage;
    if (cme_points) data.cme_points = cme_points;

    data.brandlogo = brandConfig?.logoUrl || null;
    data.brandConfig = {
        logoUrl: brandConfig?.logoUrl || null,
        logoUrlModal: brandConfig?.logoUrlModal || null,
        posterUrl: brandConfig?.posterUrl || null,
        detailText: brandConfig?.detailText || null,
        sizeMultiple: brandConfig?.sizeMultiple || 1,
    };
    if (synopsis && synopsis.length) data.synopsis = synopsis;
    data.isTest = test;
    data.isPrivate = isPrivate;
    data.hideFromUpcoming = !!hideFromUpcoming;
    data.virtualBackgroundImages = {
        virtualBackgroundImages:
            Array.isArray(selectedBackgroundImages) &&
            selectedBackgroundImages.length
                ? selectedBackgroundImages
                : [],
    };

    data.discussionStats = discussionStats;
    data.extraData = {...extraData, expectedDurationinms: (extraData.expectedDurationinms * 60000)};
    data.supportedby = selectedImagesSupportedBy.map(i => ({imageurl: i}));
    data.associations = selectedImagesAssociations.map(i => ({imageurl: i}));
    data.productsby = productsBy;
    data.disclaimer_title = disclaimer_title;
    data.disclaimer_body = disclaimer_body;
    data.iscomingsoon = iscomingsoon;
    data.otp_enabled = otp_enabled;
    data.partner_handle = partner_handle;
    data.regform1_fields = {
        name: regform1_fields_name, city: regform1_fields_city,
        specialty: regform1_fields_specialty, state: regform1_fields_state,
        institute: regform1_fields_institute, phone: regform1_fields_phone,
        disclaimer: regform1_fields_disclaimer, checkboxes: regform1_fields_checkboxes
    };

    if (
        (arraySpeak && arraySpeak.length) ||
        (arrayInternalHosts && arrayInternalHosts.length) ||
        (arrayModerators && arrayModerators?.length) ||
        (arrayADSpotsAdmins && arrayADSpotsAdmins?.length)
    ) {
        if (recordingURL && recordingURL.length) {
            (data.rc_url = recordingURL), (data.rcduration = recordingDuration);
        }
        let hostsData = [];
        let allHostsData = [];

        if (Array.isArray(arraySpeak)) {
            let data = arraySpeak.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 1,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayInternalHosts?.length) {
            let data = arrayInternalHosts.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayModerators?.length) {
            let data = arrayModerators.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: true,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayADSpotsAdmins?.length) {
            let data = arrayADSpotsAdmins.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: true,
            }));

            hostsData.push(...data);
        }

        allHostsData.push(...hostsData);
        // console.log('allHostsData', allHostsData);
        data.hosts = allHostsData?.reduce((accumulator, current) => {
            const existingObject = accumulator.find(
                item => item.accountid === current.accountid,
            );

            if (existingObject) {
                existingObject.iscommentmoderator =
                    existingObject.iscommentmoderator ||
                    current.iscommentmoderator;
                existingObject.adSpotAdmin =
                    existingObject.adSpotAdmin || current.adSpotAdmin;
                existingObject.iscommentmoderator =
                    existingObject.iscommentmoderator ||
                    current.iscommentmoderator;
            } else {
                accumulator.push(current);
            }

            return accumulator;
        }, []);
    }

    if (arraySpec && arraySpec.length)
        data.specialityIds = arraySpec.map(e => e.value);

    if (arrayTags && arrayTags?.length) {
        data.tagIds = arrayTags?.map(tag => tag.value);
    }

    return (
        <Container className="discussion-container">
            <Col>
                <Modal show={show} onHide={onClose} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Update Discussion</Modal.Title>
                            <BiEdit onClick={editEnable} />
                        </Modal.Header>
                        <Modal.Body>
                            {/* Discusisonid */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    Discussionid
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    type="text"
                                    disabled={true}
                                    placeholder="Discussionid "
                                    value={discussionid}
                                />
                            </InputGroup>
                            {/* Title */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    Title
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    required
                                    type="text"
                                    disabled={editDisable}
                                    placeholder="Discussion Title"
                                    value={title}
                                    onChange={handleTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a discussion title.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* One Liner */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    One Liner
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    // required
                                    name="oneLiner"
                                    type="text"
                                    disabled={editDisable}
                                    placeholder="One Liner"
                                    value={extraData?.oneLiner}
                                    onChange={handleExtraData}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a One Liner.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Certifier */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    Certifier
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    // required
                                    name="certifier"
                                    disabled={editDisable}
                                    type="text"
                                    placeholder="Certifier"
                                    value={extraData?.certifier}
                                    onChange={handleExtraData}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a One Liner.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Date and Time */}
                            <InputGroup className="row">
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    Date and Time
                                </Form.Label>
                                {/* Date */}
                                <Form.Control
                                    className="m-2"
                                    type="date"
                                    required
                                    value={date}
                                    disabled={editDisable}
                                    onChange={handleDate}
                                />
                                {/* Time */}
                                <Form.Control
                                    className="m-2"
                                    type="time"
                                    required
                                    disabled={editDisable}
                                    value={time}
                                    onChange={handleTime}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select discussion date and time.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Select Tagging For Club Or Conference */}
                            <Form.Group controlId="tagFor">
                                <Form.Label>Tag For</Form.Label>
                                <Form.Select
                                    name="tagFor"
                                    disabled={editDisable}
                                    required
                                    value={tageFor}
                                    onChange={handleTagFor}
                                >
                                    <option value={''}>None</option>

                                    <option value={'club'}>Club</option>
                                    <option value={'conference'}>
                                        Conference
                                    </option>
                                </Form.Select>
                            </Form.Group>

                            {/* Club */}
                            {tageFor === 'club' && (
                                <InputGroup className="row mt-3">
                                    <Form.Label htmlFor="basic-url">
                                        Select Club
                                    </Form.Label>
                                    <Select
                                        isDisabled={editDisable}
                                        onChange={val => handleClub(val)}
                                        isSearchable
                                        value={[club]}
                                        options={JSON.parse(
                                            JSON.stringify(clubsData),
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a club.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            )}

                            {/* Conference */}
                            {tageFor === 'conference' && (
                                <InputGroup className="row mt-3">
                                    <Form.Label htmlFor="basic-url">
                                        Conference ID
                                    </Form.Label>
                                    <Form.Control
                                        id="conferenceid"
                                        name="conferenceid"
                                        disabled={editDisable}
                                        aria-describedby="basic-addon3"
                                        value={conferenceid || ''}
                                        onChange={e =>
                                            setConferenceId(
                                                e?.target?.value || '',
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please add conference id.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            )}

                            {/* Tag */}
                            <InputGroup className="row mt-3">
                                <Form.Label htmlFor="basic-url">
                                    Select Tags
                                </Form.Label>
                                <Select
                                    components={animatedComponents}
                                    onChange={val => handleTags(val)}
                                    isSearchable
                                    isClearable
                                    isDisabled={editDisable}
                                    closeMenuOnSelect={false}
                                    value={arrayTags}
                                    isMulti
                                    options={tags || []}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select a club.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Hosts */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Featured Hosts
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={arraySpeak}
                                isMulti
                                isDisabled={editDisable}
                                onChange={val => handleSpeakers(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />
                            {/* Internal Hosts */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Internal Hosts
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                value={arrayInternalHosts}
                                isDisabled={editDisable}
                                onChange={val => handleInternalHosts(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />

                            {/* Moderators */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Comment Moderators
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                value={arrayModerators || []}
                                isDisabled={editDisable}
                                onChange={val => handleModerators(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />

                            {/* Ad Spot Admins */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select AD Spot Admins
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                value={arrayADSpotsAdmins || []}
                                isDisabled={editDisable}
                                onChange={val => handleADSportAdmins(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />

                            {/* Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Image URL
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                disabled={editDisable}
                                value={image}
                                onChange={handleImage}
                            />
                            {image && (
                                <Image
                                    src={image}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* Jpeg Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                JPEG Image URL 
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                value={jpegImage}
                                disabled={editDisable}
                                onChange={e =>
                                    setJpegImage(e?.target?.value || '')
                                }
                            />
                            {jpegImage && (
                                <Image
                                    src={jpegImage}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* Web Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Web Image URL (3:1)
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                value={webImage}
                                disabled={editDisable}
                                onChange={e =>
                                    setWebImage(e?.target?.value || '')
                                }
                            />
                            {webImage && (
                                <Image
                                    src={webImage}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* Brand Logo */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Small
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                disabled={editDisable}
                                name="brandLogoSmall"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.logoUrl}
                                onChange={handleBrandChange}
                            />

                            {brandConfig?.logoUrl && (
                                <Image
                                    src={brandConfig?.logoUrl}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Full
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                disabled={editDisable}
                                name="brandLogoFull"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.logoUrlModal || ''}
                                onChange={handleBrandChange}
                            />

                            {brandConfig?.logoUrlModal && (
                                <Image
                                    src={brandConfig?.logoUrlModal}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )} */}

                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Full
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                disabled={editDisable}
                                name="posterURL"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.posterUrl || ''}
                                onChange={handleBrandChange}
                            />

                            {brandConfig?.posterUrl && (
                                <Image
                                    src={brandConfig?.posterUrl}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}
                            <Form.Label
                                htmlFor="basic-detailText"
                                className="mt-3"
                            >
                                Brand Logo Detail Text
                            </Form.Label>
                            <Form.Control
                                id="basic-detailText"
                                disabled={editDisable}
                                name="detailText"
                                aria-describedby="basic-detailText"
                                value={brandConfig?.detailText || ''}
                                onChange={handleBrandChange}
                            />

                            <Form.Group>
                                <Form.Label>Logo Scale</Form.Label>
                                <Form.Control
                                    type="number"
                                    disabled={editDisable}
                                    name={`scale`}
                                    value={brandConfig?.sizeMultiple}
                                    defaultValue={1}
                                    onChange={handleBrandChange}
                                    min="1"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>CME Points</Form.Label>
                                <Form.Control
                                    type="number"
                                    disabled={editDisable}
                                    name={`cme_points`}
                                    value={cme_points}
                                    defaultValue={0}
                                    onChange={e => setcme_points(e?.target?.value || 0)}
                                    min="0"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Expected Duration (upcoming)</Form.Label>
                                <Form.Control
                                    type="number"
                                    disabled={editDisable}
                                    name={`expectedDurationinms`}
                                    value={extraData?.expectedDurationinms}
                                    defaultValue={60}
                                    onChange={handleExtraData}
                                    min="60"
                                />
                            </Form.Group>

                            {/* Recoding URL */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Recording URL
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                disabled={editDisable}
                                value={recordingURL}
                                onChange={handleRecordingURL}
                            />
                            {/* Recoding Duration */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Recording Duration in ms
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                disabled={editDisable}
                                value={recordingDuration}
                                onChange={handleRecordingDuration}
                            />

                            {/* BG Selector */}

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Virtual Background Image Selection
                                </h5>
                                <BackGroundInputSelector
                                    predefinedOptions={predefinedBGImageOptions}
                                    onChange={handleBackgroundImageChange}
                                    selectedImages={selectedBackgroundImages}
                                    initBackgroundImages={initBackgroundImages}
                                />
                            </Form.Group>

                            {/* Synopsis */}
                            <Form.Label className="mt-3">Synopsis </Form.Label>
                            <Form.Control
                                // required
                                as="textarea"
                                aria-label="With textarea"
                                disabled={editDisable}
                                value={synopsis}
                                onChange={handleSynopsis}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a synopsis.
                            </Form.Control.Feedback>

                            {/* Summary */}
                            <Form.Label className="mt-3">Summary </Form.Label>
                            <Form.Control
                                as="textarea"
                                aria-label="With textarea"
                                disabled={editDisable}
                                value={summary}
                                onChange={e =>
                                    setSummary(e?.target?.value || '')
                                }
                            />

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Supported By
                                </h5>

                                <GenImageSelector
                                    selectedImages={selectedImagesSupportedBy}
                                    setselectedImages={setselectedImagesSupportedBy}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Associations
                                </h5>

                                <GenImageSelector
                                    selectedImages={selectedImagesAssociations}
                                    setselectedImages={setselectedImagesAssociations}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Products By
                                </h5>

                                <ProductsBySelector
                                    data={productsBy ?? {}}
                                    setdata={setproductsBy}
                                />
                            </Form.Group>


                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Disclaimer section
                                </h5>
                                <Form.Control
                                    id="disclaimer_title"
                                    disabled={editDisable}
                                    name="Section Title"
                                    placeholder="Section Title"
                                    aria-describedby="basic-addon3"
                                    value={disclaimer_title}
                                    onChange={e => setdisclaimer_title(e.target.value)}
                                />
                                <div style={{height: 20}}></div>
                                <Form.Control
                                    id="disclaimer_body"
                                    as="textarea"
                                    disabled={editDisable}
                                    name="Section Text"
                                    placeholder="Section Text"
                                    aria-describedby="basic-addon3"
                                    value={disclaimer_body}
                                    onChange={e => setdisclaimer_body(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Partner Page?
                                </h5>
                                <Form.Control
                                    id="partner_handle"
                                    disabled={editDisable}
                                    name="Partner Page URL (/partner/session)"
                                    placeholder="Partner Page URL (/partner/session)"
                                    aria-describedby="basic-addon3"
                                    value={partner_handle}
                                    onChange={e => setpartner_handle(e.target.value.replace(/[^a-zA-Z0-9-_/]/g, ''))}
                                />
                                <p>*Please make sure ^ is unique & valid (no spaces or special characters)</p>
                                <div style={{height: 20}}></div>
                                <Form.Check
                                    type="switch"
                                    id="otp_enabled"
                                    label="OTP Verification"
                                    disabled={editDisable}
                                    checked={otp_enabled}
                                    value={otp_enabled}
                                    onChange={() => setotp_enabled(v => !v)}
                                />
                                {!otp_enabled && (
                                    <>
                                        <Form.Label style={{fontWeight: 'bold', fontStyle: 'italic', marginTop: 15, fontSize: 'large'}}>Registration Form Fields</Form.Label>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>Name: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_name_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_name.show} value={!!regform1_fields_name?.show}
                                                onChange={() => setregform1_fields_name(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_name_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_name.mandatory} value={!!regform1_fields_name?.mandatory}
                                                onChange={() => setregform1_fields_name(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>City: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_city_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_city.show} value={!!regform1_fields_city?.show}
                                                onChange={() => setregform1_fields_city(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_city_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_city.mandatory} value={!!regform1_fields_city?.mandatory}
                                                onChange={() => setregform1_fields_city(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>Specialty: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_specialty_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_specialty.show} value={!!regform1_fields_specialty?.show}
                                                onChange={() => setregform1_fields_specialty(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_specialty_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_specialty.mandatory} value={!!regform1_fields_specialty?.mandatory}
                                                onChange={() => setregform1_fields_specialty(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>State: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_state_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_state.show} value={!!regform1_fields_state?.show}
                                                onChange={() => setregform1_fields_state(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_state_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_state.mandatory} value={!!regform1_fields_state?.mandatory}
                                                onChange={() => setregform1_fields_state(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>Institute: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_institute_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_institute.show} value={!!regform1_fields_institute?.show}
                                                onChange={() => setregform1_fields_institute(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_institute_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_institute.mandatory} value={!!regform1_fields_institute?.mandatory}
                                                onChange={() => setregform1_fields_institute(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>Phone: </p>
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_phone_show"
                                                label="Show" disabled={editDisable}
                                                checked={!!regform1_fields_phone.show} value={!!regform1_fields_phone?.show}
                                                onChange={() => setregform1_fields_phone(v => ({...v, show: !v.show}))}
                                            />
                                            <div style={{width: 10}} />
                                            <Form.Check
                                                type="switch" id="regform1_fields_phone_mandatory"
                                                label="Mandatory" disabled={editDisable}
                                                checked={!!regform1_fields_phone.mandatory} value={!!regform1_fields_phone?.mandatory}
                                                onChange={() => setregform1_fields_phone(v => ({...v, mandatory: !v.mandatory}))}
                                            />
                                        </div>
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <p>Disclaimer: </p>
                                            <div style={{width: 10}} />
                                            <Form.Control
                                                id="regform1_fields_disclaimer"
                                                disabled={editDisable}
                                                name="Registration form disclaimer"
                                                placeholder="Registration form disclaimer"
                                                aria-describedby="basic-addon3"
                                                value={regform1_fields_disclaimer}
                                                onChange={e => setregform1_fields_disclaimer(e.target.value)}
                                            />
                                        </div>
                                        <p>Checkboxes: </p>
                                        {console.log('-regform1_fields_checkboxes', regform1_fields_checkboxes)}
                                        {regform1_fields_checkboxes.map((val, idx) => (
                                            <div style={{display:'flex', flexDirection: 'row'}} key={`regform1_fields_checkboxes${idx}div`}>
                                                <Form.Control
                                                    id={`regform1_fields_checkboxes${idx}text`}
                                                    disabled={editDisable}
                                                    name={`Checkbox ${idx+1} Text`}
                                                    placeholder={`Checkbox ${idx+1} Text`}
                                                    aria-describedby="basic-addon3"
                                                    value={val.text}
                                                    onChange={e => { let temp = regform1_fields_checkboxes; temp[idx].text = e.target.value; setregform1_fields_checkboxes([...temp]); }}
                                                />
                                                <div style={{width: 10}} />
                                                <Form.Check
                                                    type="switch" id={`regform1_fields_checkboxes${idx}mandatory`}
                                                    label="Mandatory" disabled={editDisable}
                                                    checked={!!val.mandatory} value={!!val.mandatory}
                                                    onChange={e => { let temp = regform1_fields_checkboxes; temp[idx].mandatory = !temp[idx].mandatory; setregform1_fields_checkboxes([...temp]); }}
                                                />
                                                <div style={{width: 5}} />
                                                <Button onClick={() => {
                                                    let temp = regform1_fields_checkboxes; temp.splice(idx,1);
                                                    setregform1_fields_checkboxes([...temp]);
                                                }} variant="danger" size='sm' style={{height: 30}}>X</Button>
                                            </div>
                                        ))}
                                        <Button onClick={() => { setregform1_fields_checkboxes(v => [...v, {text: '', mandatory: false}]); }} size="sm">Add</Button>
                                    </>
                                )}
                            </Form.Group>


                            <Form.Group controlId="platform">
                                <Form.Label>Platform</Form.Label>
                                <Form.Select
                                    name="platform"
                                    disabled={editDisable}
                                    required
                                    value={platform?.platform || 'medflix'}
                                    onChange={e =>
                                        setPlatform({
                                            ...platform,
                                            platform: e.target.value,
                                        })
                                    }
                                >
                                    <option value={'medflix'}>Medflix</option>
                                    <option value={'youtube'}>Youtube</option>
                                </Form.Select>
                            </Form.Group>
                            {platform?.platform === 'youtube' && (
                                <Form.Group controlId="videoId">
                                    <Form.Label className="mt-3">
                                        Video ID{' '}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={editDisable}
                                        value={platform.videoid}
                                        onChange={e =>
                                            setPlatform({
                                                ...platform,
                                                videoid: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            )}

                            {/* Stats */}
                            {status === 'ended' && (
                                <Form.Group controlId="discussionStats">
                                    <Row>
                                        <Col>
                                            <Form.Label className="mt-3">
                                                View Count{' '}
                                            </Form.Label>
                                            <Form.Control
                                                // type={'number'}
                                                aria-label="With textarea"
                                                disabled={editDisable}
                                                value={
                                                    discussionStats?.viewcount
                                                }
                                                onChange={e =>
                                                    setDiscussionStats({
                                                        ...discussionStats,
                                                        viewcount: Number(
                                                            e?.target?.value,
                                                        ),
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label className="mt-3">
                                                Rating Count{' '}
                                            </Form.Label>
                                            <Form.Control
                                                // type={'number'}
                                                aria-label="With textarea"
                                                disabled={editDisable}
                                                value={
                                                    discussionStats?.ratingcount
                                                }
                                                onChange={e =>
                                                    setDiscussionStats({
                                                        ...discussionStats,
                                                        ratingcount: Number(
                                                            e?.target?.value,
                                                        ),
                                                    })
                                                }
                                            />
                                        </Col>

                                        <Col>
                                            <Form.Label className="mt-3">
                                                Rating Avg{' '}
                                            </Form.Label>
                                            <Form.Control
                                                // type={'number'}
                                                aria-label="With textarea"
                                                disabled={editDisable}
                                                value={
                                                    discussionStats?.ratingavg
                                                }
                                                onChange={e =>
                                                    setDiscussionStats({
                                                        ...discussionStats,
                                                        ratingavg: Number(
                                                            e?.target?.value,
                                                        ),
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            {/* IsTest */}
                            <InputGroup className="mt-3">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Test"
                                    disabled={editDisable}
                                    checked={test === 1}
                                    value={test}
                                    onChange={handleTest}
                                />

                                {/* {handlePfizerQuiz} */}
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    disabled
                                    label="Pfizer Quiz"
                                    checked={isPfizerQuiz === 1}
                                    value={isPfizerQuiz}
                                    onChange={handlePfizerQuiz}
                                />

                                {/* isPrivate */}
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    label="Private"
                                    disabled={editDisable}
                                    checked={isPrivate === 1}
                                    value={isPrivate}
                                    onChange={handlePrivate}
                                />

                                {/* hideFromUpcoming */}
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    label="Hide from Upcoming"
                                    disabled={editDisable}
                                    checked={!!hideFromUpcoming}
                                    value={hideFromUpcoming}
                                    onChange={handlehideFromUpcoming}
                                />

                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Coming Soon"
                                    disabled={editDisable}
                                    checked={iscomingsoon === 1}
                                    value={iscomingsoon}
                                    onChange={() => setiscomingsoon(iscomingsoon ? 0 : 1)}
                                />
                            </InputGroup>

                            {/* Extra Data */}
                            <InputGroup className="mt-3">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    name="replayWontBeAvaliable"
                                    label="Replay Wont Be Avaliable"
                                    disabled={editDisable}
                                    checked={
                                        extraData?.replayWontBeAvaliable ===
                                        true
                                    }
                                    value={extraData?.replayWontBeAvaliable}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Certified} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    type="switch"
                                    id="custom-switch"
                                    name="isCertified"
                                    disabled={editDisable}
                                    label="Certified"
                                    checked={extraData?.isCertified === true}
                                    value={extraData?.isCertified}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Quiz} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    disabled={editDisable}
                                    type="switch"
                                    id="custom-switch"
                                    name="isQuizDiscussion"
                                    label="Quiz"
                                    checked={
                                        extraData?.isQuizDiscussion === true
                                    }
                                    value={extraData?.isQuizDiscussion}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Partner Discussion} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    disabled={editDisable}
                                    type="switch"
                                    id="custom-switch"
                                    name="isPartnerDiscussion"
                                    label="Partner Discussion"
                                    checked={
                                        extraData?.isPartnerDiscussion === true
                                    }
                                    value={extraData?.isPartnerDiscussion}
                                    onChange={handleExtraData}
                                />


                                {/* {To Stop All Automated Comms} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    disabled={editDisable}
                                    type="switch"
                                    id="custom-switch"
                                    name="stopAutomatedComms"
                                    label="Stop Automated Communications"
                                    checked={
                                        extraData?.stopAutomatedComms === true
                                    }
                                    value={extraData?.stopAutomatedComms}
                                    onChange={handleExtraData}
                                />

                                {/* {To Hide key details} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    disabled={editDisable}
                                    type="switch"
                                    id="custom-switch"
                                    name="hideKeyDetails"
                                    label="Hide 'Key Details' section"
                                    checked={
                                        extraData?.hideKeyDetails === true
                                    }
                                    value={extraData?.hideKeyDetails}
                                    onChange={handleExtraData}
                                />

                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    disabled={editDisable}
                                    type="switch"
                                    id="custom-switch"
                                    name="subtitle_hide"
                                    label="Hide Subtitles"
                                    checked={
                                        extraData?.subtitle_hide === true
                                    }
                                    value={extraData?.subtitle_hide}
                                    onChange={handleExtraData}
                                />

                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                variant={isLoading ? 'warming' : 'success'}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
}

export default UpdateDiscussion;
