import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Image,
    Form,
    InputGroup,
    FormControl,
    Button,
} from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

interface InputSelectorProps {
    setdata: any;
    data: any
}

const InputSelector: React.FC<InputSelectorProps> = ({
    setdata,
    data
}) => {
console.log('-asd', data)
  const [newimage, setnewimage] = useState('');

    return (
        <Container>
            <Row>
                <Col>
                    <div>
                        {/* <Form.Label>Add custom images</Form.Label> */}
                        <FormControl
                            placeholder="Section Title"
                            value={data.title}
                            onChange={e => setdata(val => {
                              val.title = e.target.value;
                              return {...val};
                            })}
                        />
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Enter image URL"
                                value={newimage}
                                onChange={e => setnewimage(e.target.value)}
                            />
                            <Button onClick={() => {
                              setdata(val => {
                                val.data = [{imageurl: newimage}, ...(val.data ?? [])]
                                return {...val};
                              })
                              setnewimage('');
                            }}>Add</Button>
                        </InputGroup>
                    </div>
                    <div>
                      {(data.data ?? []).map((dataval, index) => (
                            <div
                                key={`${index}`}
                                className="position-relative"
                                style={{
                                    marginBottom: '10px',
                                    width: '400px',
                                }}
                            >
                                <Image
                                    src={dataval.imageurl}
                                    thumbnail
                                    fluid={true}
                                    style={{
                                        width: '50vw',
                                        height: '18vh',
                                    }}
                                />
                                <Button
                                    variant="danger"
                                    size="sm"
                                    className="remove-button position-absolute top-0 end-0"
                                    onClick={() => setdata(val => {
                                      val.data = val.data.filter(i => i.imageurl !== dataval.imageurl);
                                      return {...val};
                                    })}
                                    style={{
                                        background:
                                            'rgba(255, 0, 0, 0.7)',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        height: '40px',
                                        width: '40px',
                                        opacity: '1',
                                    }}
                                >
                                    <MdClear style={{ color: 'white' }} />
                                </Button>

                                <FormControl
                                  placeholder="Enter Redirect Url"
                                  value={dataval.external_ctaurl ?? ''}
                                  onChange={(event) => setdata(val => {
                                    val.data = val.data.map(i => {
                                      if (i.imageurl === dataval.imageurl) i.external_ctaurl = event.target.value;
                                      return {...i};
                                    });
                                    console.log('-all-', val);
                                    return {...val};
                                  })}
                              />
                            </div>
                        ))
                      }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default InputSelector;
