import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

export const gettags = async () => {
    const response = await axios.post(`${globalURLS.baseURL}videovault/gettags`, {}, globalURLS.config);
    return response.data?.data ?? [];
};

export const getvideos = async () => {
    const response = await axios.post(`${globalURLS.baseURL}videovault/getvideos`, {}, globalURLS.config);
    return response.data?.data ?? [];
};

export const deletevideo = async (filename: string) => {
  const response = await axios.post(`${globalURLS.baseURL}videovault/deletevideo`, {filename}, globalURLS.config);
  return response.data?.success;
};


export const uploadVideo = async (formData, onProgress) => {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${globalURLS.baseURL}videovault/upload`, true);

      xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
              const percent = Math.round((event.loaded / event.total) * 100);
              onProgress(percent);
          }
      };

      xhr.onload = () => {
          if (xhr.status === 200) resolve(JSON.parse(xhr.responseText));
          else reject(new Error("Upload failed"));
      };

      xhr.onerror = () => reject(new Error("Network error"));
      xhr.send(formData);
  });
};
