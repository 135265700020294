import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import CreateLinks from './CreateLinks';
import Analytics from './Analytics';

const MagicLink: React.FC = () => {
  const [activeView, setActiveView] = useState<string>('create');
    
  return (
    <Container fluid className="shortener-page d-flex flex-column align-items-center" style={{ overflowY: 'auto' }}>
      <div className="buttons-container mb-4">
        <Button
          variant={activeView === 'create' ? 'dark' : 'outline-dark'}
          onClick={() => setActiveView('create')}
          className="me-2"
        >
          Create Magic Links
        </Button>
        <Button
          variant={activeView === 'analytics' ? 'dark' : 'outline-dark'}
          onClick={() => setActiveView('analytics')}
          disabled
          className='pointer-events-none'
        >
          View Analytics
        </Button>
      </div>

      {activeView === 'create' && <CreateLinks />}
      {activeView === 'analytics' && <Analytics />}
    </Container>
  )
}

export default MagicLink