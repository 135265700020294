import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { globalURLS } from '../../Global/urls';
import SuccessModal from './SuccessModal';
import { max, set } from 'date-fns';

const data = {
    link: "https://medflix.app/logme/2cce8a25-1ce2-44e7-976c-5e8b7c99e4b3",
    passcode: "1583",
    maxAttempts: 10,
    expiry: "Thu, 20 Feb 2025 17:23:32 GMT"
}

const CreateLinks: React.FC = () => {
  const [haveAccountOrPhone, setHaveAccountOrPhone] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [phone, setPhone] = useState('');
  const [destinationLink, setDestinationLink] = useState('');
  const [expiryInHours, setExpiryInHours] = useState('');
  const [pin, setPin] = useState('');
  const [maxAttempts, setMaxAttempts] = useState('');
  const [hostAccess, setHostAccess] = useState(false);
  const [discussionId, setDiscussionId] = useState('');
  const [resp, setResp] = useState(null);

  useEffect(() => {
    if(haveAccountOrPhone && (accountId || phone)) {
      return;
    }
    setHostAccess(false);
  }, [haveAccountOrPhone, accountId, phone]);

  // Handle the form submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Determine which ID to send
    const defaultId = '1864fb51-4639-4c08-8075-ef4cd0543634';
    const idToSend = haveAccountOrPhone && accountId ? accountId : undefined;
    const phoneToSend = haveAccountOrPhone && phone ? phone : undefined;

    const payload = {
      accountid: idToSend || phoneToSend ? idToSend : defaultId,
      phone: phoneToSend,
      destinationlink: destinationLink ? destinationLink : undefined,
      expiryInHours: expiryInHours ? Number(expiryInHours) : undefined,
      pin: pin ? Number(pin) : undefined,
      maxAttempts: maxAttempts ? Number(maxAttempts) : 10,
      host_access: hostAccess,
      host_discussionid: hostAccess ? discussionId : undefined
    };    

    try {
      const response = await axios.post(`${globalURLS.baseURL}createLoginLink`, payload, globalURLS.config);
      if(response?.data?.success && response?.data?.data) {
        const data = response.data.data;
        data.maxAttempts = maxAttempts ? Number(maxAttempts) : 10;
        setResp(data);
        return;
      }
      // Handle success response
      setResp({ error: response?.data?.message });
    } catch (error) {
      // Handle error
      setResp({ error: error?.response?.data?.message || error?.message });
    } finally {
      // Handle finally
      setHaveAccountOrPhone(false);
      setAccountId('');
      setPhone('');
      setDestinationLink('');
      setExpiryInHours('');
      setPin('');
      setHostAccess(false);
      setDiscussionId('');
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      const isNumber = !isNaN(Number(value));
      const length = value.length;
      const lastEnteredChar = value.charAt(length - 1);
      if(lastEnteredChar === '.') {
        return;
      }

      if (isNumber && length <= 10 || value === '') {
        setPhone(value);
      }
  }

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      const isNumber = !isNaN(Number(value));
      const length = value.length;
      const lastEnteredChar = value.charAt(length - 1);
      if(lastEnteredChar === '.') {
        return;
      }

      if (isNumber && length <= 4 || value === '') {
        setPin(value);
      }
  }

  const handleMaxAttemptsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === '0') return; // Do not allow 0 attempts
      setMaxAttempts(value);
  }

  return (
    <>
    <div className="container my-5">
      {/* <h2>Create Links</h2> */}
      <Form onSubmit={handleSubmit} className='d-flex flex-column gap-3'>
        <Form.Group controlId="destinationLink">
          <Form.Label>Destination Link<sup>*</sup></Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter destination link"
            value={destinationLink}
            onChange={(e) => setDestinationLink(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="expiryInHours">
          <Form.Label>Link Expiry (in hours)</Form.Label>
          <Form.Control
            type="number"
            placeholder='Enter link expiry (in hours)'
            value={expiryInHours}
            onChange={(e) => setExpiryInHours(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="pin">
          <Form.Label>Enter Custom Pin</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter pin"
            value={pin}
            onChange={handlePinChange}
          />
        </Form.Group>

        <Form.Group controlId="maxAttempts">
          <Form.Label>Enter Max Attempts (default is 10)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter max attempts"
            value={maxAttempts}
            onChange={handleMaxAttemptsChange}
          />
        </Form.Group>

        {/* Conditional Inputs for AccountId or Phone */}
        <Form.Group controlId="hostAccess" className="d-flex align-items-center gap-2">
            <Form.Label className="mb-0">Do You Have Account ID or Phone Number?</Form.Label>
            <Form.Check
                className='mb-0'
                type="switch"
                id="custom-switch"
                label=""
                checked={haveAccountOrPhone}
                onChange={() => setHaveAccountOrPhone(!haveAccountOrPhone)}
            />
        </Form.Group>

        {haveAccountOrPhone && (
            <>
                <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter phone"
                    value={phone}
                    onChange={handlePhoneChange}
                />
                </Form.Group>
                <Form.Group controlId="accountId">
                <Form.Label>AccountId</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter account id"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                />
                </Form.Group>
            </>
        )}

        {haveAccountOrPhone && (accountId || phone) && <>
            {/* Host Access Section - Switch Instead of Radio Button */}
            <Form.Group controlId="hostAccess" className="d-flex align-items-center gap-2">
                <Form.Label className="mb-0">Do You Want Host Access?</Form.Label>
                <Form.Check
                    className='mb-0'
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={hostAccess}
                    onChange={() => setHostAccess(!hostAccess)}
                />
            </Form.Group>
            {/* Discussion ID Input - Only visible if Host Access is Yes */}
            {hostAccess && (
            <Form.Group controlId="discussionId">
                <Form.Label>Enter Discussion ID<sup>*</sup></Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter discussion id"
                    value={discussionId}
                    onChange={(e) => setDiscussionId(e.target.value)}
                    required
                />
            </Form.Group>
            )}
        </>}

        <Button variant="dark" type="submit" className='align-self-start'>
          Create Link
        </Button>
      </Form>
    </div>
    {resp && <SuccessModal isOpen={resp !== null} onClose={() => setResp(null)} data={resp} />}
    </>
  );
};

export default CreateLinks;
