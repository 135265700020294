import React, { useState } from 'react';
import BlitzModal from './BlitzModal';
import { upgradeBlitzAgenda } from '../helpers/utils';
import { IBlitzHall, IBlitzInfoType, IPayLoad } from '../typings';
import { v4 as uuidv4 } from 'uuid';

type AddHallModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (payload: IPayLoad) => void;
    blitzInfo: IBlitzInfoType;
    activeDayRank: number;
};

const AddHallModal: React.FC<AddHallModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    blitzInfo,
    activeDayRank,
}) => {
    const [newHallName, setNewHallName] = useState('');

    const handleAddHall = () => {
        if (!newHallName) return;
        const newHall: IBlitzHall = {
            id: uuidv4(),
            name: newHallName,
            day_rank: activeDayRank,
        };
        upgradeBlitzAgenda(
            {
                blitz_short_id: blitzInfo.short_id,
                days: blitzInfo.days,
                halls: [...blitzInfo.halls, newHall],
                sessions: blitzInfo.sessions,
            },
            onSuccess,
        );
        onClose();
    };
    return (
        <BlitzModal isOpen={isOpen} onClose={onClose}>
            <div className="text-center fw-bold fs-4 mb-3">Add Hall</div>
            {/* date input */}
            <label className="fw-bold fs-6 mb-0 mx-3">Hall Name</label>
            <div className="d-flex gap-1 mb-4 mx-3">
                <input
                    type="text"
                    className="form-control"
                    value={newHallName}
                    onChange={e => setNewHallName(e.target.value)}
                    placeholder="Enter Hall Name"
                />
                <button className="btn btn-primary" onClick={handleAddHall}>
                    Add
                </button>
            </div>
        </BlitzModal>
    );
};

export default AddHallModal;
