import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Spinner } from "react-bootstrap";
import debounce from "lodash.debounce";
import { globalURLS } from '../../Global/urls.js';

const NewDatasetApproval = () => {
  const [showType, setShowType] = useState("all");
  const [filterBy, setFilterBy] = useState("pending");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [mapMode, setMapMode] = useState("new");
  const [searchResults, setSearchResults] = useState([]);
  const [preexisting_item, setpreexisting_item] = useState(null);
  const [newName, setNewName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setsearchQuery] = useState("");
  const [city1, setcity1] = useState(null);
  const [submitloading, setsubmitLoading] = useState(false);
  const [degrees1, setdegrees1] = useState([]);

  useEffect(() => {
    fetchData();
  }, [showType, filterBy]);

  const fetchData = async (showLoader=true) => {
    if (showLoader) setLoading(true);
    const response = await fetch(`${globalURLS.baseURL}tis_getall`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ type: showType, status: filterBy })
    });
    const result = await response.json();
    if (result.success) setData(result.data);
    if (showLoader) setLoading(false);
  };

  const handleApproveClick = (row) => {
    setSelectedRow(row);
    setNewName(row.name);
    setMapMode("new");
    setModalShow(true);
    setSearchResults([]);
    setpreexisting_item(null);
    setsearchQuery("");
    setcity1(null);
    setdegrees1([]);
  };

  const handleSearch = debounce(async (type, query) => {
    if (!query) return;
    const response = await fetch(`${globalURLS.baseURL}tis_datasearch`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ type, query })
    });
    const result = await response.json();
    if (result.success) setSearchResults(result.data);
  }, 500);

  const handleSubmit = async () => {
    if (mapMode === "map" && !preexisting_item) {
      alert("Please type and select a relevant existing item");
      return;
    }
    else if (mapMode === "new" && !newName?.length) {
      alert("Please type a proper name to update");
      return;
    }
    else {
      setsubmitLoading(true);
      const payload = {
        id: selectedRow.id,
        preexisting_itemid: mapMode === "map" ? preexisting_item?.id : undefined,
        new_name: mapMode === "new" ? newName : undefined,
        city1_id: city1?.id,
        degrees1: (degrees1?.length ? degrees1 : []).map(i => i.name)
      };
  
      const response = await fetch(`${globalURLS.baseURL}tis_process`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
      });
      const result = await response.json();
      if (result.success) {
        setModalShow(false);
        fetchData(false);
      }
      else alert("Problem occurred. Please try again");
      setsubmitLoading(false);
    }
  };

  const paginatedData = data.slice((currentPage - 1) * 20, currentPage * 20);
  return (
    <div style={{ padding: 20 }}>
      <h2 style={{color: '#000'}}>New Dataset Approval (User Generated)</h2>
      <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
        <label>Show:</label>
        <Form.Select value={showType} onChange={(e) => { setShowType(e.target.value); setFilterBy("pending"); setCurrentPage(1); }}>
          <option value="all">All</option>
          <option value="speciality">Specialty</option>
          <option value="degree">Degree</option>
          <option value="city">City</option>
          <option value="institute">Institute</option>
        </Form.Select>
        <label>Filter By:</label>
        <Form.Select value={filterBy} onChange={(e) => { setFilterBy(e.target.value); setCurrentPage(1); }}>
          <option value="pending">Pending</option>
          <option value="processed">Processed</option>
        </Form.Select>
      </div>
      {loading ? <Spinner animation="border" /> : (
        <>
          <h5>Showing {data?.length ?? 0} items</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Name</th>
                <th>Status</th>
                <th>Date</th>
                {filterBy === "pending" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map(row => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.type}</td>
                  <td>{row.name}</td>
                  <td>{row.status}</td>
                  <td>{row.date}</td>
                  {filterBy === "pending" && (
                    <td><Button onClick={() => handleApproveClick(row)}>Approve</Button></td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <div style={{ marginTop: 10 }}>
        <Button disabled={currentPage === 1} onClick={() => setCurrentPage(p => p - 1)}>Prev</Button>
        <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
        <Button disabled={data.length <= currentPage * 20} onClick={() => setCurrentPage(p => p + 1)}>Next</Button>
      </div>
      <div style={{height: 100}}></div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton><Modal.Title>Approve Dataset</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>ID: {selectedRow?.id}</p>
          <p>Type: {selectedRow?.type}</p>
          <p>Name: {selectedRow?.name}</p>
          <div>
            <Button variant={mapMode === "new" ? "dark" : "outline-secondary"} onClick={() => { setMapMode("new"); setsearchQuery(""); setpreexisting_item(null); setNewName(selectedRow?.name); setcity1(null); setSearchResults([]); }}>
              Add as New
            </Button>
            <Button variant={mapMode === "map" ? "dark" : "outline-secondary"} onClick={() => { setMapMode("map"); setsearchQuery(""); setpreexisting_item(null); setNewName(selectedRow?.name); setcity1(null); setSearchResults([]); }}>
              Map to Existing
            </Button>
          </div>
          <div style={{height: 20}}></div>
          {mapMode === "new" ? (
            <>
              <Form.Control type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
              <div style={{height: 10}}></div>
              {selectedRow?.type === 'institute' && (
                <>
                  <Form.Control type="text" placeholder="Search City..." value={searchQuery} onChange={(e) => {setsearchQuery(e.target.value); handleSearch('city', e.target.value); }} />
                  {!!searchResults?.length && (
                    <div style={{ position: "absolute", background: "white", border: "1px solid #ccc", width: "100%", zIndex: 10, maxHeight: 180, overflow: 'scroll', }}>
                        {searchResults.map(val => (
                            <div key={val.id} onClick={() => { setcity1(val); setSearchResults([]); }} style={{ padding: "5px", cursor: "pointer", borderBottomWidth: 1, borderColor: '#ddd', borderBlock: 'inherit' }}>
                                {val.name}
                            </div>
                        ))}
                    </div>
                  )}
                  {!!city1 && (<><p>Selected: {city1?.name}</p></>)}
                </>
              )}
              {selectedRow?.type === 'speciality' && (
                <>
                  <Form.Control type="text" placeholder="Search Degree..." value={searchQuery} onChange={(e) => {setsearchQuery(e.target.value); handleSearch('degree', e.target.value); }} />
                  {!!searchResults?.length && (
                    <div style={{ position: "absolute", background: "white", border: "1px solid #ccc", width: "100%", zIndex: 10, maxHeight: 180, overflow: 'scroll', }}>
                        {searchResults.map(val => (
                            <div key={val.id} onClick={() => { setdegrees1(i => ([...i, val])); setSearchResults([]); }} style={{ padding: "5px", cursor: "pointer", borderBottomWidth: 1, borderColor: '#ddd', borderBlock: 'inherit' }}>
                                {val.name}
                            </div>
                        ))}
                    </div>
                  )}
                  <p>Selected Degrees: {(degrees1?.length ? degrees1 : []).map(i => i.name).join(' | ')}</p>
                </>
              )}
            </>
          ) : (
            <>
              <Form.Control type="text" placeholder="Search..." value={searchQuery} onChange={(e) => {setsearchQuery(e.target.value); handleSearch(selectedRow?.type, e.target.value); }} />
              {!!searchResults?.length && (
                <div style={{ position: "absolute", background: "white", border: "1px solid #ccc", width: "100%", zIndex: 10, maxHeight: 180, overflow: 'scroll', }}>
                    {searchResults.map(val => (
                        <div key={val.id} onClick={() => { setpreexisting_item(val); setSearchResults([]); }} style={{ padding: "5px", cursor: "pointer", borderBottomWidth: 1, borderColor: '#ddd', borderBlock: 'inherit' }}>
                            {val.name}
                        </div>
                    ))}
                </div>
              )}
              {!!preexisting_item && (<><p>Selected: {preexisting_item?.name}</p></>)}
            </>
          )}
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit} disabled={submitloading}>{submitloading ? 'Please wait...' : 'Submit'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewDatasetApproval;
