import React, { useState } from 'react';
import BlitzModal from './BlitzModal';
import { upgradeBlitzAgenda } from '../helpers/utils';
import { v4 as uuidv4 } from 'uuid';
import {
    IBlitzInfoType,
    IBlitzSession,
    IBlitzSpeakerInfo,
    IBlitzTalkInfo,
    IPayLoad,
} from '../typings';
import { Button, Form } from 'react-bootstrap';

type AddSpeakerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (payload: IPayLoad) => void;
    talk: IBlitzTalkInfo;
    session: IBlitzSession;
    blitzInfo: IBlitzInfoType;
    speakers: IBlitzSpeakerInfo[];
};

const AddSpeakerModal: React.FC<AddSpeakerModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    talk,
    session,
    blitzInfo,
    speakers,
}) => {
    const [name, setName] = useState('');
    const [profilepic, setProfilepic] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const newSpeaker: IBlitzSpeakerInfo = {
            id: id || uuidv4(),
            name,
            profilepic,
            description,
        };

        upgradeBlitzAgenda(
            {
                blitz_short_id: blitzInfo.short_id,
                days: blitzInfo.days,
                halls: blitzInfo.halls,
                sessions: blitzInfo.sessions.map((s: IBlitzSession) => {
                    if (s.id === session.id) {
                        return {
                            ...s,
                            talks: s.talks.map(t => {
                                if (t.id === talk.id) {
                                    return {
                                        ...t,
                                        speakers: [...t.speakers, newSpeaker],
                                    };
                                } else {
                                    return t;
                                }
                            }),
                        };
                    } else {
                        return s;
                    }
                }),
            },
            onSuccess,
        );
        onClose();
    };

    return (
        <BlitzModal isOpen={isOpen} onClose={onClose}>
            <div className="text-center fw-bold fs-4 mb-3">Add Talk</div>
            {/* date input */}
            <Form className="mb-3 mx-3" onSubmit={handleSubmit}>
                {speakers.length > 0 && (
                    <div className="mb-3 d-flex flex-column gap-1">
                        <label htmlFor="speakerselect" className="mb-0">
                            Select Existing Speaker:
                        </label>
                        <select
                            id="speakerselect"
                            name="speakerselect"
                            value={id}
                            onChange={e => {
                                const id = e.target.value;
                                const sp = speakers.find(sp => sp.id === id);
                                setName(sp?.name || '');
                                setDescription(sp?.description || '');
                                setProfilepic(sp?.profilepic || '');
                                setId(sp?.id || '');
                            }}
                            className="form-select"
                        >
                            {' '}
                            <option value={''} selected>
                                New Speaker
                            </option>
                            {speakers
                                .filter(
                                    speaker =>
                                        !talk?.speakers?.includes?.(speaker),
                                )
                                .map(sp => (
                                    <option key={sp.id} value={sp.id}>
                                        {sp.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}

                <p className="mb-3 fw-semibold">
                    To add new speaker, first select New Speaker from the above
                    dropdown.
                </p>

                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="name" className="mb-0">
                        Speaker Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Enter speaker name"
                        readOnly={!!id}
                        onChange={e => setName(e.target.value)}
                        required
                        className="p-2"
                    />
                </div>
                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="name" className="mb-0">
                        Speaker Description:
                    </label>
                    <textarea
                        id="name"
                        name="name"
                        value={description}
                        placeholder="Enter speaker description (optional)"
                        readOnly={!!id}
                        onChange={e => setDescription(e.target.value)}
                        className="p-2 w-100"
                        rows={3}
                    />
                </div>
                <div className="mb-3 d-flex flex-column gap-1">
                    <label htmlFor="profilepic" className="mb-0">
                        Profile Image Url:
                    </label>
                    <input
                        type="text"
                        id="profilepic"
                        name="profilepic"
                        placeholder="Profile image url (optional)"
                        readOnly={!!id}
                        value={profilepic}
                        onChange={e => setProfilepic(e.target.value)}
                        className="p-2"
                    />
                </div>

                <Button variant="primary" type="submit" className="w-100">
                    Add Speaker
                </Button>
            </Form>
        </BlitzModal>
    );
};

export default AddSpeakerModal;
