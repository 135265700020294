import React, { useState } from 'react';
import BlitzModal from './BlitzModal';
import { upgradeBlitzAgenda } from '../helpers/utils';
import { IBlitzDay, IBlitzInfoType, IPayLoad } from '../typings';
import { v4 as uuidv4 } from 'uuid';

type AddDayModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (payload: IPayLoad) => void;
    blitzInfo: IBlitzInfoType;
};

const AddDayModal: React.FC<AddDayModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    blitzInfo,
}) => {
    const [newDate, setNewDate] = useState<any>(null);

    const handleAddDay = () => {
        if (!newDate) return;
        const dateEpoch = new Date(newDate).setHours(0, 0, 0, 0);
        const newDay: IBlitzDay = {
            id: uuidv4(),
            date: dateEpoch,
            rank: blitzInfo.days.length + 1,
        };
        upgradeBlitzAgenda(
            {
                blitz_short_id: blitzInfo.short_id,
                days: [...blitzInfo.days, newDay],
                halls: blitzInfo.halls,
                sessions: blitzInfo.sessions,
            },
            onSuccess,
        );
        onClose();
    };
    return (
        <BlitzModal isOpen={isOpen} onClose={onClose}>
            <div className="text-center fw-bold fs-4 mb-3">Add Day</div>
            {/* date input */}
            <label className="fw-bold fs-6 mb-0 mx-3">Date</label>
            <div className="d-flex gap-1 mb-4 mx-3">
                <input
                    type="date"
                    className="form-control"
                    value={newDate}
                    onChange={e => setNewDate(e.target.value)}
                />
                <button className="btn btn-primary" onClick={handleAddDay}>
                    Add
                </button>
            </div>
        </BlitzModal>
    );
};

export default AddDayModal;
