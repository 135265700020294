import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Spinner, Alert, Form } from "react-bootstrap";
import {updaterequest_getall, updaterequest_process} from "./api";

const ProfileUpdateDashboard: React.FC = () => {
  const [requests, setRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRequest, setSelectedRequest] = useState<any | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [sendComms, setsendComms] = useState<boolean>(true);

  const itemsPerPage = 20;

  useEffect(() => {
    fetchRequests(true);
  }, []);

  const fetchRequests = async (showLoader) => {
    if (showLoader) setLoading(true);
    try {
      const response = await updaterequest_getall();
      setRequests(response);
    } catch (error) {
      console.error("Error fetching requests", error);
    }
    if (showLoader) setLoading(false);
  };

  const handleProcessRequest = async (type: "accept" | "reject") => {
    if (!selectedRequest) return;
    setProcessing(true);
    try {
      const response = await updaterequest_process({
        request_id: selectedRequest.id,
        accountid: selectedRequest.accountid,
        type, sendComms
      });
      if (response) {
        setSelectedRequest(null);
        fetchRequests(false);
        setsendComms(true);
      } else {
        setError("Problem occurred. Please try again.");
      }
    } catch (error) {
      setError("Problem occurred. Please try again.");
    }
    setProcessing(false);
  };

  const handleFileClick = (fileUrl: string) => {
    if (fileUrl?.length) {
      const isPdfFile = fileUrl.toLowerCase().endsWith(".pdf");
      // setIsPdf(isPdfFile);
      if (isPdfFile) {
        setTimeout(() => {
          window.open(fileUrl, "_blank");
        }, 500); 
      }
      else {
        setSelectedFile(fileUrl);
      }
    }
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = requests.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(requests.length / itemsPerPage);

  return (
    <div style={{ padding: "20px" }}>
      <h2 style={{color: '#000'}}>Profile Update Requests</h2>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Account ID</th>
                <th>Date</th>
                <th>Name</th>
                <th>Id-Proof</th>
                <th>Med-License</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((req) => (
                <tr key={req.id}>
                  <td>{req.accountid}</td>
                  <td>{req.date}</td>
                  <td>{`${req.current.firstname} ${req.current.lastname ?? ''}`}</td>
                  <td>
                    <span 
                      className="text-primary d-block cursor-pointer" 
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleFileClick(req.verification_docs?.id)}
                    >
                      View ID
                    </span>
                  </td>
                  <td>
                    <span 
                      className="text-primary d-block cursor-pointer" 
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleFileClick(req.verification_docs?.medlicense)}
                    >
                      View License
                    </span>
                  </td>
                  <td>
                    <Button onClick={() => setSelectedRequest(req)}>View Details</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <Button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              Previous
            </Button>
            <span style={{ margin: "0 10px" }}>Page {currentPage} of {totalPages}</span>
            <Button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              Next
            </Button>
          </div>
        </>
      )}

      {selectedRequest && (
        <Modal show onHide={() => { setsendComms(true); setSelectedRequest(null); }} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Update Request Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <Table bordered style={{width: '100%'}}>
              <thead>
                <tr>
                  <th></th>
                  <th>Current</th>
                  <th>New</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedRequest.current).map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td style={{overflow: 'scroll'}}>{selectedRequest.current[key] || ""}</td>
                    <td style={{overflow: 'scroll'}}>{selectedRequest.new[key] || ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Form.Check type="switch" id="custom-switch" label="Send Communications" checked={sendComms} value={sendComms} onChange={() => setsendComms(v => !v)} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setsendComms(true); setSelectedRequest(null); }}>
              Cancel
            </Button>
            <Button
              variant="danger"
              disabled={processing}
              onClick={() => handleProcessRequest("reject")}
            >
              Reject
            </Button>
            <Button
              variant="success"
              disabled={processing}
              onClick={() => handleProcessRequest("accept")}
            >
              Accept
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* File Modal */}
      <Modal show={!!selectedFile} onHide={() => setSelectedFile(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedFile ? (
            <img 
              src={selectedFile} 
              alt="Document Preview" 
              className="img-fluid"
            />
          ) : (
            <p>No file selected</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedFile && (
            <Button variant="primary" href={selectedFile} target="_blank" rel="noopener noreferrer">
              Download File
            </Button>
          )}
          <Button variant="secondary" onClick={() => setSelectedFile(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfileUpdateDashboard;
