import React, { useEffect, useState } from "react";
import Hls from "hls.js";
import { Modal, Button, Form, Spinner, Container, Row, Col, Table, ProgressBar } from "react-bootstrap";
import { gettags, getvideos, uploadVideo, deletevideo } from "./api";
import { FaCopy } from 'react-icons/fa';

const VideoVault = () => {
    const [tags, setTags] = useState([]);
    const [videos, setVideos] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [videoModal, setVideoModal] = useState({ show: false, url: "", type: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 20;
    const [tagInput, setTagInput] = useState("");
    const [filteredTagList, setFilteredTagList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [createHLS, setCreateHLS] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [newtagInput, setnewTagInput] = useState("");
    const [newfilteredTagList, setnewFilteredTagList] = useState([]);
    const [newselectedTags, setnewSelectedTags] = useState([]);
    const [showDeleteModal, setshowDeleteModal] = useState(null);
    const [isDeleting, setisDeleting] = useState(false);
    const [createTranscript, setcreateTranscript] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);

    let itv1 = null;

    useEffect(() => {
      fetchTags();
      fetchVideos();
      itv1 = setInterval(() => {
        fetchVideos(false);
      }, 5000)
      return () => clearInterval(itv1);
    }, []);


    useEffect(() => {
      if (videoModal.show && videoModal.type === "hls" && Hls.isSupported()) {
          const video1 = document.getElementById("hls-video");
          const hls = new Hls();
          hls.loadSource(videoModal.url);
          hls.attachMedia(video1);
      }
    }, [videoModal]);
  

    const fetchTags = async () => {
        try {
            const data = await gettags();
            setTags(data);
            setFilteredTagList(data);
        } catch (error) {
            console.error("Error fetching tags:", error);
        }
    };

    const fetchVideos = async (withloading=true) => {
        if (withloading) setLoading(true);
        try {
            const data = await getvideos();
            setVideos(data);
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
        if (withloading) setLoading(false);
    };


    const handleClearFilter = () => {
        setSelectedTags([]);
        setSearchText("");
        fetchVideos();
    };

    const handleTagSelect = (tag) => {
        if (!selectedTags.includes(tag)) {
            setSelectedTags([...selectedTags, tag]);
        }
        setTagInput("");
    };

    const handlenewTagSelect = (tag) => {
      if (!newselectedTags.includes(tag)) {
          setnewSelectedTags([...newselectedTags, tag]);
      }
      setnewTagInput("");
    };

    const handleRemoveTag = (tag) => {
      setSelectedTags(val => val.filter(i => i !== tag));
    }
    const handlenewRemoveTag = (tag) => {
      setnewSelectedTags(val => val.filter(i => i !== tag));
    }

    const handleTagInputChange = (e) => {
        const value = e.target.value;
        setTagInput(value);
        setFilteredTagList(tags.filter(tag => tag.toLowerCase().includes(value.toLowerCase())));
    };

    const handlenewTagInputChange = (e) => {
      const value = e.target.value;
      setnewTagInput(value);
      setnewFilteredTagList(tags.filter(tag => tag.toLowerCase().includes(value.toLowerCase())));
  };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleDelete = async () => {
      if (showDeleteModal?.filename) {
        setisDeleting(true);
        const resp = await deletevideo(showDeleteModal.filename);
        if (resp) {
          fetchVideos(false);
          setshowDeleteModal(null)
        }
        setisDeleting(false);
      }
      else setshowDeleteModal(null);
    }

    const handleUpload = async () => {
      if (!selectedFile) return;
      
      setIsUploading(true);
      setUploadProgress(0);
      
      try {
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("createHLS", createHLS);
          formData.append("createTranscript", createTranscript);
          formData.append("tags", newselectedTags);
          formData.append("duration", videoDuration);

          await uploadVideo(formData, (progress) => {
              setUploadProgress(progress);
          });

          setUploadModal(false);
          fetchVideos(false); // Refresh video list after upload
          fetchTags();
      } catch (error) {
          console.error("Upload failed:", error);
      } finally {
          setIsUploading(false);
          setSelectedFile(null);
          setnewSelectedTags([]);
          setnewTagInput("");
      }
  };

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    let filteredVideos = videos;
    if (selectedTags.length > 0) {
      filteredVideos = filteredVideos.filter(video => video.tags.some(tag => selectedTags.includes(tag)));
    }
    if (searchText.trim() !== "") {
      filteredVideos = filteredVideos.filter(video => (video.original_info?.filename ?? video.filename).toLowerCase().includes(searchText.toLowerCase()));
    }
    const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);

    return (
        <Container fluid style={{ padding: "20px", width: "80vw" }}>
            <Row className="align-items-center">
                <Col><h3>Showing {filteredVideos.length} items</h3></Col>
                <Col className="text-end">
                    <Button onClick={() => {
                      setUploadModal(true); 
                      setCreateHLS(false);
                      setcreateTranscript(false);
                    }}>Upload</Button>
                </Col>
            </Row>
            <Row className="align-items-center mt-3">
                <Col md="auto">
                    <span>Search by: </span>
                </Col>
                <Col md={4}>
                    <Form.Control type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Type to search filenames" />
                </Col>
            </Row>
            <Row className="align-items-center mt-3">
                <Col md="auto">
                    <span>Filter by: </span>
                </Col>
                <Col md={4} style={{ position: "relative" }}>
                    <Form.Control type="text" value={tagInput} onChange={handleTagInputChange} placeholder="Type to search tags" />
                    {tagInput && (
                        <div style={{ position: "absolute", background: "white", border: "1px solid #ccc", width: "100%", zIndex: 10 }}>
                            {filteredTagList.map(tag => (
                                <div key={tag} onClick={() => handleTagSelect(tag)} style={{ padding: "5px", cursor: "pointer" }}>
                                    {tag}
                                </div>
                            ))}
                        </div>
                    )}
                </Col>
                {!!selectedTags.length && <Col md="auto">
                    <Button variant="secondary" onClick={handleClearFilter} style={{ marginLeft: "10px" }}>Clear</Button>
                </Col>}
            </Row>
            <p>Selected tags: {selectedTags.map(tag => (
                <Button key={tag} variant="outline-primary" style={{ margin: "5px" }}>
                    {tag} <span onClick={() => handleRemoveTag(tag)} style={{ cursor: "pointer", marginLeft: "5px" }}>✖</span>
                </Button>
            ))}</p>
            <hr />
            {loading ? <Spinner animation="border" /> : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{fontSize: 14}}>Filename</th>
                            <th style={{fontSize: 14}}>Status</th>
                            <th style={{fontSize: 14}}>Duration</th>
                            <th style={{fontSize: 14}}>Original URL</th>
                            <th style={{fontSize: 14}}>HLS URL</th>
                            <th style={{fontSize: 14}}>Tags</th>
                            <th style={{fontSize: 14}}>Uploaded</th>
                            <th style={{fontSize: 14}}>Del</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentVideos.map(video => (
                            <tr key={video.filename}>
                                <td style={{fontSize: 14, overflow: 'scroll'}}>
                                    {video.original_info?.filename ?? video.filename}
                                    <br />
                                    <p style={{fontSize: 12}}>({((video.original_info?.bytes ?? 0) / 1000000).toFixed(2)} MB)</p>
                                </td>
                                <td style={{fontSize: 14}}>{video.status}</td>
                                <td style={{fontSize: 12}}>{video.original_info?.duration ? `${((video.original_info?.duration ?? 0) * 1000).toFixed(0)} ms` : ``}</td>
                                <td>
                                  {!['Pending','Uploading'].includes(video.status) ? (
                                    <>
                                      <Button variant="link" onClick={() => setVideoModal({ show: true, url: video.original_playbackurl, type: "mp4" })} style={{fontSize: 12}}>Play Original</Button>
                                      <FaCopy fontSize={10} onClick={() => copyToClipboard(video.original_playbackurl)}/>
                                    </>
                                  ) : <></>}                                  
                                </td>
                                <td>
                                  {video.hls_url?.length && video.status === 'Ready' ? (
                                    <>
                                      <Button variant="link" onClick={() => setVideoModal({ show: true, url: video.hls_url, type: "hls" })} style={{fontSize: 12}}>Play HLS</Button>
                                      <FaCopy fontSize={10} onClick={() => copyToClipboard(video.hls_url)}/>
                                    </>
                                  ) : <></>}
                                </td>
                                <td>{video.tags.map(i => (
                                  <Button key={`${video.filename}_${i}`} variant="outline-primary" style={{ margin: "2px", padding: "0px", paddingLeft: 3, paddingRight: 3, fontSize: 12 }}>{i}</Button>
                                ))}</td>
                                <td style={{fontSize: 14}}>{video.date}</td>
                                <td><Button onClick={() => setshowDeleteModal(video)} variant="link" style={{fontSize: 10}}>❌</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <div>
                {Array.from({ length: Math.ceil(filteredVideos.length / videosPerPage) }, (_, i) => (
                    <Button key={i} onClick={() => setCurrentPage(i + 1)} style={{ margin: "5px" }} variant={currentPage === i+1 ? 'dark' : 'secondary'}>{i + 1}</Button>
                ))}
            </div>

            <div style={{height: 100}}></div>

            <Modal show={videoModal.show} onHide={() => setVideoModal({ show: false, url: "", type: "" })} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Video Player</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {videoModal.type === "mp4" ? (
                        <video controls style={{ width: "100%" }}>
                            <source src={videoModal.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <video controls style={{ width: "100%" }} id="hls-video"></video>
                    )}
                </Modal.Body>
            </Modal>


          <Modal show={uploadModal} onHide={() => setUploadModal(false)} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Upload Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                  <Form.Label>Select MP4 File</Form.Label>
                  <Form.Control type="file" accept="video/mp4" onChange={e => {
                    const file = e.target.files[0];
                    if (file) {
                        const video = document.createElement("video");
                        video.preload = "metadata";
                        video.onloadedmetadata = () => {
                            window.URL.revokeObjectURL(video.src);
                            console.log("Video duration:", video.duration, "seconds");
                            setVideoDuration(video.duration);
                        };
                        video.src = URL.createObjectURL(file);
                        setSelectedFile(e.target.files[0])
                    }
                  }} />
              </Form.Group>

              <Form.Group className="mt-3">
                  <Form.Check
                      type="checkbox"
                      label="Create HLS stream"
                      checked={createHLS}
                      onChange={(e) => setCreateHLS(e.target.checked)}
                  />
              </Form.Group>
              {!!createHLS && (<Form.Group className="mt-3">
                  <Form.Check
                      type="checkbox"
                      label="Create Transcript"
                      checked={createTranscript}
                      onChange={(e) => setcreateTranscript(e.target.checked)}
                  />
              </Form.Group>)}
              
              <Row className="align-items-center mt-3">
                <Col md="auto">
                    <span>Tags: </span>
                </Col>
                <Col md={4} style={{ position: "relative" }}>
                    <Form.Control type="text" value={newtagInput} onChange={handlenewTagInputChange} placeholder="Type to search tags" />
                    {newtagInput && (
                        <div style={{ position: "absolute", background: "white", border: "1px solid #ccc", width: "100%", zIndex: 10 }}>
                            {newfilteredTagList.map(tag => (
                                <div key={`new${tag}`} onClick={() => handlenewTagSelect(tag)} style={{ padding: "5px", cursor: "pointer" }}>
                                    {tag}
                                </div>
                            ))}
                        </div>
                    )}
                </Col>
                {!!newtagInput.length && !newfilteredTagList.includes(newtagInput) && <Col md="auto"><Button onClick={() => handlenewTagSelect(newtagInput)} key={"newtag1"} style={{ margin: "5px" }}>Add</Button></Col>}
            </Row>
            <p>Selected tags: {newselectedTags.map(tag => (
                <Button key={`new${tag}`} variant="outline-primary" style={{ margin: "5px" }}>
                    {tag} <span onClick={() => handlenewRemoveTag(tag)} style={{ cursor: "pointer", marginLeft: "5px" }}>✖</span>
                </Button>
            ))}</p>

              {isUploading && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setUploadModal(false)}>Cancel</Button>
              <Button variant="primary" onClick={handleUpload} disabled={isUploading}>
                  {isUploading ? "Please wait. Uploading..." : "Upload"}
              </Button>
              {isUploading && <Spinner animation="border" />}
            </Modal.Footer>
          </Modal>

          <Modal show={!!showDeleteModal} onHide={() => setshowDeleteModal(null)}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure you want to DELETE PERMANENTLY ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <span>Id: {showDeleteModal?.filename}</span>
               <br></br>
               <br></br>
               <span>Name: {showDeleteModal?.original_info?.filename}</span>
               <br></br>
               <br></br>
               <span>Tags: {(showDeleteModal?.tags ?? []).join(' , ')}</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setshowDeleteModal(null)}>Cancel</Button>
              <Button variant="primary" onClick={handleDelete} disabled={isDeleting}>
                  {isDeleting ? "Deleting..." : "Delete"}
              </Button>
            </Modal.Footer>
          </Modal>

        </Container>
    );
};

export default VideoVault;
