import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import {
    IBlitzInfoType,
    IBlitzSession,
    IBlitzSpeakerInfo,
    IBlitzTalkInfo,
    IPayLoad,
} from './typings';
import { formatTime, getDateOnly, upgradeBlitzAgenda } from './helpers/utils';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import AddTalkModal from './Modal/AddTalkModal';
import AddSpeakerModal from './Modal/AddSpeakerModal';

type BlitzCardProps = {
    session: IBlitzSession;
    setSelectedSession: (session: IBlitzSession) => void;
    setAddSessionModalOpen: (open: boolean) => void;
    deleteSession: () => void;
    blitzInfo: IBlitzInfoType;
    setBlitzInfo: (blitzInfo: IBlitzInfoType) => void;
    speakers: IBlitzSpeakerInfo[];
};

const BlitzCard: React.FC<BlitzCardProps> = ({
    session,
    setSelectedSession,
    setAddSessionModalOpen,
    deleteSession,
    blitzInfo,
    setBlitzInfo,
    speakers,
}) => {
    const [open, setOpen] = useState(false);
    const [openTalkAddModal, setOpenTalkAddModal] = useState(false);
    const [selectedTalk, setSelectedTalk] = useState<IBlitzTalkInfo>(null);
    const [openSpeakerAddModal, setOpenSpeakerAddModal] = useState(false);

    const deleteTalk = (id: string) => {
        const cfrm: boolean = confirm(
            'Are you sure you want to delete this talk?',
        );

        if (cfrm) {
            upgradeBlitzAgenda(
                {
                    blitz_short_id: blitzInfo.short_id,
                    days: blitzInfo.days,
                    halls: blitzInfo.halls,
                    sessions: blitzInfo.sessions.map((s: IBlitzSession) => {
                        return s.id === session.id
                            ? { ...s, talks: s.talks.filter(t => t.id !== id) }
                            : s;
                    }),
                },
                (payload: IPayLoad) => {
                    setBlitzInfo({
                        ...blitzInfo,
                        sessions: payload.sessions,
                    });
                },
            );
        }
    };

    const deleteSpeaker = (talkId: string, speakerId: string) => {
        const cfrm: boolean = confirm(
            'Are you sure you want to delete this speaker?',
        );

        if (cfrm) {
            upgradeBlitzAgenda(
                {
                    blitz_short_id: blitzInfo.short_id,
                    days: blitzInfo.days,
                    halls: blitzInfo.halls,
                    sessions: blitzInfo.sessions.map((s: IBlitzSession) => {
                        return s.id === session.id
                            ? {
                                  ...s,
                                  talks: s.talks.map(t => {
                                      if (t.id === talkId) {
                                          return {
                                              ...t,
                                              speakers: t.speakers.filter(
                                                  sp => sp.id !== speakerId,
                                              ),
                                          };
                                      } else {
                                          return t;
                                      }
                                  }),
                              }
                            : s;
                    }),
                },
                (payload: IPayLoad) => {
                    setBlitzInfo({
                        ...blitzInfo,
                        sessions: payload.sessions,
                    });
                },
            );
        }
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <div className="d-flex justify-content-between gap-2">
                        <div className="mb-1">
                            <Card.Title>{session.name}</Card.Title>
                            <Card.Text>
                                <strong>Session Timing: </strong>
                                {getDateOnly(session.starttime)} (
                                {formatTime(session.starttime)} -{' '}
                                {formatTime(session.endtime)})
                            </Card.Text>
                        </div>
                        <div className="d-flex gap-2">
                            <Button
                                variant="outline-primary"
                                className="d-flex align-items-center gap-1 px-2"
                                style={{
                                    lineHeight: '1rem',
                                    height: '2rem',
                                    padding: '7px 0.5rem',
                                }}
                                onClick={() => {
                                    setSelectedSession(session);
                                    setAddSessionModalOpen(true);
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                variant="outline-danger"
                                className="d-flex align-items-center gap-1 px-2"
                                style={{
                                    lineHeight: '1rem',
                                    height: '2rem',
                                    padding: '7px 0.5rem',
                                }}
                                onClick={deleteSession}
                            >
                                delete
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                        {session.has_quiz ? (
                            <>
                                <p className="fs-6 py-2 mb-0 text-dark fw-semibold">
                                    Quiz Id:
                                </p>
                                {session.quiz_short_id}
                                <p className="fs-6 py-2 ms-2 mb-0 text-dark fw-semibold">
                                    Quiz Timing:
                                </p>
                                ({formatTime(session.quiz_starttime)} -{' '}
                                {formatTime(session.quiz_endtime)})
                                <p className="fs-6 py-2 ms-2 mb-0 text-dark fw-semibold">
                                    Quiz Status:
                                </p>
                                {session.quiz_status}
                            </>
                        ) : (
                            <p className="fs-6 py-2 mb-0 text-secondary fw-semibold">
                                {session.has_quiz ? 'Quiz Details:' : 'No Quiz'}
                            </p>
                        )}
                    </div>

                    {/* Button to toggle expand/collapse */}
                    <div className="d-flex gap-2 align-items-center">
                        <Card.Text className="my-0 fw-semibold">
                            Talks ({session.talks.length}):
                        </Card.Text>
                        <Button
                            variant="outline-primary"
                            className="d-flex align-items-center gap-1 px-2"
                            style={{
                                lineHeight: '1rem',
                                height: '2rem',
                                padding: '7px 0.5rem',
                            }}
                            onClick={() => setOpenTalkAddModal(true)}
                        >
                            Add Talk
                        </Button>
                        {session.talks.length > 0 && (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    width: 'fit-content',
                                }}
                                onClick={() => setOpen(!open)}
                                aria-expanded={open}
                            >
                                {open ? (
                                    <MdKeyboardArrowUp />
                                ) : (
                                    <MdKeyboardArrowDown />
                                )}
                            </div>
                        )}
                    </div>

                    {/* Collapsible section for talks */}
                    {session.talks.length > 0 && open && (
                        <div className="mt-2">
                            {session.talks.length > 0 ? (
                                session.talks.map((talk, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="mb-2 d-flex gap-1 align-items-center">
                                            <h6 className="mb-0">
                                                {talk.name}
                                            </h6>
                                            ({formatTime(talk.starttime)} -{' '}
                                            {formatTime(talk.endtime)})
                                            <Button
                                                variant="outline-primary"
                                                className="d-flex align-items-center gap-1 px-2"
                                                style={{
                                                    lineHeight: '1rem',
                                                    height: '2rem',
                                                    padding: '7px 0.5rem',
                                                }}
                                                onClick={() => {
                                                    setSelectedTalk(talk);
                                                    setOpenTalkAddModal(true);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                className="d-flex align-items-center gap-1 px-2"
                                                style={{
                                                    lineHeight: '1rem',
                                                    height: '2rem',
                                                    padding: '7px 0.5rem',
                                                }}
                                                onClick={() => {
                                                    setSelectedTalk(talk);
                                                    setOpenSpeakerAddModal(
                                                        true,
                                                    );
                                                }}
                                            >
                                                Add Speaker
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                className="d-flex align-items-center gap-1 px-2"
                                                style={{
                                                    lineHeight: '1rem',
                                                    height: '2rem',
                                                    padding: '7px 0.5rem',
                                                }}
                                                onClick={() =>
                                                    deleteTalk(talk.id || '')
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                        <div className="d-flex flex-column gap-1">
                                            {talk.speakers.map(
                                                (speaker, idx) => (
                                                    <div
                                                        key={idx}
                                                        className="d-flex align-items-center gap-1"
                                                    >
                                                        {speaker.profilepic && (
                                                            <img
                                                                src={
                                                                    speaker.profilepic
                                                                }
                                                                alt={
                                                                    speaker.name
                                                                }
                                                                className="img-fluid"
                                                                style={{
                                                                    maxWidth:
                                                                        '30px',
                                                                    borderRadius:
                                                                        '50%',
                                                                }}
                                                            />
                                                        )}
                                                        <p className="mb-0">
                                                            {speaker.name}
                                                        </p>
                                                        {/* <Button
                                                            variant="outline-primary"
                                                            className="d-flex align-items-center gap-1 px-2"
                                                            style={{
                                                                lineHeight:
                                                                    '1rem',
                                                                height: '2rem',
                                                                padding:
                                                                    '7px 0.5rem',
                                                            }}
                                                        >
                                                            Edit
                                                        </Button> */}
                                                        <Button
                                                            variant="outline-danger"
                                                            className="d-flex align-items-center gap-1 px-2"
                                                            style={{
                                                                lineHeight:
                                                                    '1rem',
                                                                height: '2rem',
                                                                padding:
                                                                    '7px 0.5rem',
                                                            }}
                                                            onClick={() =>
                                                                deleteSpeaker(
                                                                    talk.id ||
                                                                        '',
                                                                    speaker.id ||
                                                                        '',
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No talks available for this session.</p>
                            )}
                        </div>
                    )}
                </Card.Body>
            </Card>

            {openTalkAddModal && (
                <AddTalkModal
                    isOpen={openTalkAddModal}
                    onClose={() => {
                        setOpenTalkAddModal(false);
                        setSelectedTalk(null);
                    }}
                    onSuccess={(payload: IPayLoad) => {
                        setBlitzInfo({
                            ...blitzInfo,
                            sessions: payload.sessions,
                        });
                    }}
                    session={session}
                    blitzInfo={blitzInfo}
                    talk={selectedTalk}
                />
            )}

            {openSpeakerAddModal && selectedTalk && (
                <AddSpeakerModal
                    isOpen={openSpeakerAddModal}
                    onClose={() => {
                        setOpenSpeakerAddModal(false);
                        setSelectedTalk(null);
                    }}
                    onSuccess={(payload: IPayLoad) => {
                        setBlitzInfo({
                            ...blitzInfo,
                            sessions: payload.sessions,
                        });
                    }}
                    session={session}
                    blitzInfo={blitzInfo}
                    talk={selectedTalk}
                    speakers={speakers}
                />
            )}
        </>
    );
};

export default BlitzCard;
