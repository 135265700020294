import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Image,
    Form,
    InputGroup,
    FormControl,
    Button,
} from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

interface InputSelectorProps {
    setselectedImages: (selectedImages: string[]) => string[];
    selectedImages?: string[];
}

const InputSelector: React.FC<InputSelectorProps> = ({
    setselectedImages,
    selectedImages
}) => {

  const [newimage, setnewimage] = useState('');

    return (
        <Container>
            <Row>
                <Col>
                    <div>
                        {/* <Form.Label>Add custom images</Form.Label> */}
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Enter image URL"
                                value={newimage}
                                onChange={e => setnewimage(e.target.value)}
                            />
                            <Button onClick={() => {
                              setselectedImages(val => ([newimage, ...val]));
                              setnewimage('');
                            }}>Add</Button>
                        </InputGroup>
                    </div>
                    <div>
                      {selectedImages.map((image, index) => (
                            <div
                                key={`${index}`}
                                className="position-relative"
                                style={{
                                    marginBottom: '10px',
                                    width: '400px',
                                }}
                            >
                                <Image
                                    src={image}
                                    thumbnail
                                    fluid={true}
                                    style={{
                                        width: '50vw',
                                        height: '18vh',
                                    }}
                                />
                                <Button
                                    variant="danger"
                                    size="sm"
                                    className="remove-button position-absolute top-0 end-0"
                                    onClick={() => setselectedImages(val => val.filter(i => i !== image))}
                                    style={{
                                        background:
                                            'rgba(255, 0, 0, 0.7)',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        height: '40px',
                                        width: '40px',
                                        opacity: '1',
                                    }}
                                >
                                    <MdClear style={{ color: 'white' }} />
                                </Button>
                            </div>
                        ))
                      }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default InputSelector;
