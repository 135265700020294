import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { globalURLS } from '../../Global/urls';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { addIdToTalks } from './helpers/utils';

const BlitzManagement: React.FC = () => {
    const [conferences, setConferences] = useState([]);
    const [loading, setLoading] = useState(false);

    const getConference = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${globalURLS.baseURL}blitz_getconferences`,
                {},
                globalURLS.config,
            );
            if (response?.data?.success && response?.data?.data) {
                const data = response.data.data;
                console.log(data);
                setConferences(data);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getConference();
    }, []);

    return (
        <Container className="shortener-page">
            <h1 className="mb-4">Blitz Management</h1>

            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                    <span> Loading...</span>
                </div>
            ) : (
                <Row>
                    {conferences.length > 0 ? (
                        conferences.map(conference => (
                            <Col
                                sm={12}
                                md={6}
                                lg={4}
                                key={conference.id}
                                className="mb-4"
                            >
                                <Link
                                    to={`/blitz-manager/blitz/${conference.short_id}`} // Redirect to /short_id page
                                    className="conference-card d-block bg-dark text-white p-3 rounded text-center text-decoration-none transition-opacity"
                                >
                                    {conference.name}
                                </Link>
                            </Col>
                        ))
                    ) : (
                        <p>No conferences available.</p>
                    )}
                </Row>
            )}
        </Container>
    );
};

export default BlitzManagement;
